import React from 'react'
import { Link } from 'react-router-dom'
import AdminHeader from './AdminHeader.js'
import useScrollToTop from '../components/useScrollToTop.js';


const AdminEdit = () => {
    useScrollToTop();

  return (
    <div>
         <div>
            <AdminHeader />
            <div className='mx-auto max-w-auto px-6 lg:px-8'>
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6  py-10'>
                    <Link to="/admincomision"> <div className='ring-1 ring-gray-300 hover:bg-gray-100 cursor-pointer p-4 bg-gray-50 space-y-3'>
                        <div className='space-y-3'>
                            <p className=' font-semibold'>Edit Comission</p>
                        </div>
                        <p className='border border-gray-300'></p>
                        <p className='text-gray-400 font-semibold text-sm'>View Details</p>
                    </div> </Link>

                    <Link to='/admintopbar'> <div className='ring-1 ring-gray-300 hover:bg-gray-100 cursor-pointer p-4 bg-gray-50 space-y-3'>
                        <div className='space-y-3'>
                            <p className='font-semibold'>Edit topbar</p>
                        </div>
                        <p className='border border-gray-300'></p>
                        <p className='text-gray-400 font-semibold text-sm'>View Details</p>
                    </div></Link>

                    <Link to='/adminpolicy'>   <div className='ring-1 ring-gray-300 hover:bg-gray-100 cursor-pointer p-4 bg-gray-50 space-y-3'>
                        <div className='space-y-3'>
                            <p className='font-semibold'>Edit Privacy Policy</p>
                        </div>
                        <p className='border border-gray-300'></p>
                        <p className='text-gray-400 font-semibold text-sm'>View Details</p>
                    </div></Link>

                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminEdit
