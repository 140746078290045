import React from 'react'
import { Link } from 'react-router-dom'
import { FaExclamationTriangle } from 'react-icons/fa'

const Notfoundpage = () => {
  return (
    <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <FaExclamationTriangle className="text-red-500 text-4xl mb-1 " />
      <div className="text-center">
        <p className="text-base font-semibold text-black">404</p>
        <h1 className="mt-1 text-2xl font-semibold text-gray-900 sm:text-2xl">Page not found</h1>
        <p className="mt-2 text-base  text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/"
            className="rounded-md bg-black px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Go back home
          </Link>
        </div>
      </div>
    </main>

  )
}

export default Notfoundpage
