import React from 'react'
import Header from '../Header/Header.js';
import Footer from './Footer.js';
import parse from 'html-react-parser';
import { useQuery } from '@apollo/client';
import { GET_GENERAL_SETTING } from '../graphql/Mutation/GeneralSetting.js';
import f333Image from '../asset/img/f333.jpg';
import useScrollToTop from '../components/useScrollToTop.js';


const PrivacyPolicy = () => {
  useScrollToTop();

  const { data } = useQuery(GET_GENERAL_SETTING);
  return (
    <>
      <Header />
      <div className='relative'>

        <img src={f333Image} className='w-full bg' alt="Privacy Policy" />
        <p className='font-bold text-white text-2xl absolute bottom-6 left-6 z-30 p-4'>
          Privacy Policy
        </p>
      </div>
      <div className='mx-auto max-w-auto px-4 sm:px-6 py-12 lg:px-8'>
        <div>
          {data?.getGeneralSettings?.prohibitedList ? parse(data?.getGeneralSettings?.prohibitedList) : ''}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
