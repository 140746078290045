import { gql } from "@apollo/client";

export const GET_SUPPORT= gql`
query GetSupport($page: Int, $limit: Int, $filter: String, $sort: userSort, $search: String, $from: String, $to: String, $status: Boolean) {
  getSupport(page: $page, limit: $limit, filter: $filter, sort: $sort, search: $search, from: $from, to: $to,status: $status) {
    count
    data {
      id
      name
      mobile
      awbNo
      orderID
      message
      createdAt
      isActive
        order {
        id
        customerId
        order_number
        pickupAddress {
          name
          phone
          address1
          city
          stateId {
            id
            name
          }
          zipCode
          customerId
        }
        deliveryAddress {
          name
          phone
          address1
          city
          zipCode
          customerId
        }
        pickupDate
        stateId
        trackStatus
        order_amount
        order_percentage
        package_weight
        isDeleted
        isActive
        status
        courier_name
        paymentDetails
        manifest
        shipment_id
        order_id
        courier_id
        token
        label
        warehouse_name
        createdBy
        awb_number
        ProductPrice
        ProductName
        updatedBy
        createdAt
        updatedAt
        paymentSessionId
        cfToken
        cfOrderId
        paymentStatus
        package_weight_Length
      }
    }
  }
}
`

export const UPDATE_SUPPORT =gql`

mutation Mutation($updateSupportId: String, $isActive: Boolean) {
  updateSupport(id: $updateSupportId, isActive: $isActive)
}
`