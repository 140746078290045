import React from 'react';

const SpinLoader = ({ classNamecolor }) => {
  return (
    <div className="flex items-center justify-center ">
      <div className={`w-5 h-5 border-2 border-t-transparent ${classNamecolor === 'true' ? 'border-black w-12 h-12' : 'border-white'}  rounded-full animate-spin`}></div>
    </div>
  );
};

export default SpinLoader;
