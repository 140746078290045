import React, { useState } from 'react'
import AdminHeader from './AdminHeader.js'
import { useMutation, useQuery } from '@apollo/client'
import { ADD_TOPBAR, ADD_VALUATION, DELETE_TOPBAR, GET_VALUATION, UPDATE_TOPBAR } from '../graphql/Mutation/Topbar.js'
import { toast } from 'react-toastify'
import { GET_TOPBAR } from '../graphql/Query/TopBar.js'
import NoDataFound from '../components/NoDataFound.js'
import useScrollToTop from '../components/useScrollToTop.js';


const AdminTopbar = () => {
  useScrollToTop();

    const [topBarInput, setTopBarInput] = useState({ topBar: '', content: '' });
    const [targetString, setTargetString] = useState();
    const { data, refetch } = useQuery(GET_TOPBAR);
    const { data: getvaluation, refetch: refetchValuation } = useQuery(GET_VALUATION);
    const [addTopBar] = useMutation(ADD_TOPBAR);
    const [editTopBar] = useMutation(UPDATE_TOPBAR);
    const [deleteTopBar] = useMutation(DELETE_TOPBAR);
    const [addValuation] = useMutation(ADD_VALUATION);
    const handleInsertTopBar = () => {
        addTopBar({
            variables: {
                "input": {
                    "content": targetString,
                }
            }
        })
            .then(async ({ data }) => {
                refetch();
                toast.success("TopBar Added successfully!", { autoClose: 2000 });
                setTargetString('');
            })
            .catch((err) => {
                toast.error(err.message, { autoClose: 2000 });
            });
    }
    const handleEditTopBar = (id) => {
        editTopBar({
            variables: {
                "input": {
                    "content": targetString,
                    "id": id,
                }
            }
        })
            .then(async ({ data }) => {
                refetch()
                toast.success("TopBar Update successfully!", { autoClose: 2000 });
                setTargetString('');
            })
            .catch((err) => {
                toast.error(err.message, { autoClose: 2000 });
            });
    }
    const handleDeleteTopBar = (id) => {
        deleteTopBar({
            variables: {
                "deleteTopBarId": id,
            }
        })
            .then(async ({ data }) => {
                refetch();
                refetchValuation();
                toast.success("TopBar Delete successfully!", { autoClose: 2000 });
            })
            .catch((err) => {
                toast.error(err.message, { autoClose: 2000 });
            });
    }
    const handleAddValuation = () => {
        if (topBarInput.topBar && topBarInput.content) {
            addValuation({ variables: { topBar: topBarInput.topBar, content: topBarInput.content } })
                .then(() => {
                    setTopBarInput({ topBar: '', content: '' });
                    refetchValuation();
                    toast.success('Valuations added successfully!');
                })
                .catch((err) => toast.error(err.message));
        } else {
            toast.error('Valuations Not added!');

        }
    };
    return (
        <div>
            <AdminHeader />
            <div className="mx-auto max-w-auto px-2 sm:px-6 lg:px-8 my-10">
                <div className="mb-3">
                    <p className="flex h-10 items-center justify-center bg-slate-900 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
                        {data?.getTopBar?.content}
                    </p>
                </div>
                <p className='font-semibold text-lg mb-1'>Topbar : </p>
                <div className="flex flex-col md:flex-row md:space-x-4">
                    <div className="mb-4 md:mb-0 w-full">
                        <input
                            type="text"
                            value={targetString}
                            onChange={(e) => setTargetString(e.target.value)}
                            placeholder='Enter Topbar'
                            className="mt-1 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 w-full p-2 border border-gray-700 rounded-md"
                        />
                    </div>
                    {!data?.getTopBar?.content ? <button
                        className="py-1 px-4 bg-black text-white font-semibold rounded-md w-full md:w-auto"
                        onClick={handleInsertTopBar}
                    >
                        Submit
                    </button> :
                        <button
                            className="py-1 px-4 bg-black text-white font-semibold rounded-md w-full md:w-auto"
                            onClick={() => handleEditTopBar(data?.getTopBar?.id)}
                        >
                            edit
                        </button>

                    }

                    <button
                        className="py-1 mt-3 md:mt-0 px-4 bg-black text-white font-semibold rounded-md w-full md:w-auto"
                        onClick={() => { handleDeleteTopBar(data?.getTopBar?.id) }}
                    >
                        delete
                    </button>
                </div>

                <div className='my-10'>
                    <h2 className='font-semibold text-lg mb-1'>TopBar Management :</h2>
                    <div className='space-x-0 md:space-x-3 space-y-2 md:space-y-0'>

                        <input
                            type="text"
                            placeholder="Content"
                            value={topBarInput.content}
                            className="mt-1 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 w-full md:w-auto p-2 border border-gray-700 rounded-md"
                            onChange={(e) => setTopBarInput({ ...topBarInput, content: e.target.value })}
                        />
                        <input
                            type="text"
                            placeholder="TopBar"
                            value={topBarInput.topBar}
                            className="mt-1 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 w-full p-2 md:w-auto border border-gray-700 rounded-md"
                            onChange={(e) => setTopBarInput({ ...topBarInput, topBar: e.target.value })}
                        />
                        <button onClick={handleAddValuation} className="py-2 px-4 bg-black text-white font-semibold rounded-md w-full md:w-auto">Add Valuation</button>
                    </div>
                </div>

                <div className="overflow-x-auto rounded-t-xl  border border-gray-200">
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">No.</td>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Title</td>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Description</td>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Submit</td>
                            </tr>
                        </thead>
                        <tbody>
                            {getvaluation?.getTopBarValuation && getvaluation?.getTopBarValuation?.length > 0 ? (
                                getvaluation?.getTopBarValuation.map((row, index) => (
                                    <tr key={index} className='hover:bg-gray-50'>
                                        <td className="py-2.5 text-sm px-4 border-b">{index + 1}</td>
                                        <td className="py-2.5 text-sm px-4 border-b">{row?.topBar}</td>
                                        <td className="py-2.5 text-sm px-4 border-b">{row?.content}</td>
                                        <td className="py-2.5 text-sm px-4 border-b"> <button onClick={() => handleDeleteTopBar(row?.id)}
                                            className="px-4 py-1 border border-slate-900 text-slate-900 rounded-md hover:bg-slate-900 hover:text-white bg-white transition-colors duration-300"
                                        >
                                            Delete
                                        </button></td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="9">
                                        <NoDataFound />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>


        </div>
    )
}

export default AdminTopbar
