import React, { useState } from 'react';
import AdminHeader from './AdminHeader.js';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_ADVERTISE, DELETE_ADvertise } from '../graphql/Mutation/Advertise.js';
import { GET_ADVERTISE } from '../graphql/Query/Advertise.js';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomPagination from '../components/Pagination.js';
import useScrollToTop from '../components/useScrollToTop.js';


const AdminAd = () => {
    useScrollToTop();

    const [addAdvertise] = useMutation(ADD_ADVERTISE);
    const [deleteAdvertiseData] = useMutation(DELETE_ADvertise);
    const [page, setPage] = useState(0);
    const [limit] = useState(200);
    const { data: getAdvertise, loading, refetch } = useQuery(GET_ADVERTISE, {
        variables: {
            page: page + 1,
            limit,
            sort: {
                key: "createdAt",
                type: -1
            },
        }
    });

    const formik = useFormik({
        initialValues: {
            image: null,
            title: '',
            caption: '',
        },
        validationSchema: Yup.object({
            image: Yup.mixed().required('Image is required'),
            title: Yup.string()
                .max(50, 'Title must be 50 characters or less')
                .required('Title is required'),
            caption: Yup.string()
                .required('Caption is required'),
        }),
        onSubmit: async (values) => {
            try {
                const data = await addAdvertise({
                    variables: {
                        input: {
                            title: values.title,
                            description: values.caption,
                            image: values.image,
                        },
                    },
                });
                if (data?.data?.addBlog?.status) {
                    toast.success("Advertisement added successfully!");
                    formik.resetForm();
                    refetch();
                }
            } catch (error) {
                toast.error(error.message);
            }
        },
    });

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            formik.setFieldValue('image', reader.result);
        };
        reader.readAsDataURL(file);
    };

    const deleteAdvertise = async (id) => {
        try {
            const data = await deleteAdvertiseData({
                variables: {
                    deleteBlogId: id
                }
            });
            if (data?.data?.deleteBlog) {
                refetch();
                toast.success("Advertisement Deleted successfully!");
            }
        } catch (error) {
            toast.error(error.message);
        }
    };
    const handlePageClick = (selectedPage) => {
        setPage(selectedPage);
      };

    return (
        <div>
            <AdminHeader />
            <div className="mx-auto max-w-auto px-2 sm:px-6 lg:px-8 my-10 space-y-6  ">
                <h2 className="text-xl font-semibold">Advertisement To Customer (Notification)</h2>
                <form onSubmit={formik.handleSubmit} className='p-5 md:space-y-0 space-y-3 md:grid  grid-cols-1 gap-x-4 bg-gray-50 border rounded-lg cursor-pointer'>
                <div className='space-y-4 '>
                    <div>
                        <p className="text-sm font-medium text-gray-700">Upload Image</p>
                        <input
                            type="file"
                            name="image"
                            onChange={handleImageChange}
                            className="mt-2 border-slate-600 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 mb-2 md:mb-0 w-full md:w-none px-3.5 py-1 border rounded-md"
                        />
                        {formik.touched.image && formik.errors.image ? (
                            <div className="text-red-600 text-sm">{formik.errors.image}</div>
                        ) : null}
                    </div>
                    
                        <div>
                            <p className="text-sm font-medium text-gray-700">Title</p>
                            <input
                                type="text"
                                name="title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="Enter title"
                                className="mt-2 border-slate-600 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 mb-2 md:mb-0 w-full md:w-none px-3.5 py-1 border rounded-md"
                            />
                            {formik.touched.title && formik.errors.title ? (
                                <div className="text-red-600 text-sm">{formik.errors.title}</div>
                            ) : null}
                        </div>
                        <div>
                            <p className="text-sm font-medium text-gray-700">Caption</p>
                            <textarea
                                name="caption"
                                value={formik.values.caption}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder='Add Caption'
                                rows={4}
                                className="block w-full border border-slate-600 rounded-md px-3.5 py-2 text-gray-900 shadow-sm mt-2 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 sm:text-sm sm:leading-6"
                            />
                            {formik.touched.caption && formik.errors.caption ? (
                                <div className="text-red-600 text-sm">{formik.errors.caption}</div>
                            ) : null}
                        </div>
                        <p className='text-sm text-gray-400'>06 Jun, 2024</p>
                        <button
                            type="submit"
                            className="block w-full rounded-md bg-slate-800 px-3.5 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-slate-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                        >
                            Advertisement To Customer
                        </button>
                    </div>
                </form>

                <div className='justify-end flex'>
                    {/* <div className='mt-2'>
                        <label>
                            Items per page:
                            <select value={limit} onChange={(e) => setLimit(Number(e.target.value))}>
                            <option value={100}>100</option>
                            <option value={50}>50</option>
                            </select>
                        </label>
                    </div> */}

                    {/* <div>
                        <Pagination
                            pageCount={getAdvertise?.getBlogs?.data && Math.ceil(getAdvertise?.getBlogs?.count / limit)}
                            pageNo={page}
                            handlePageClick={(data) => handlePageClick(data)}
                        />
                    </div> */}
                    <div>
      <CustomPagination
      totalItems={getAdvertise?.getBlogs?.count || 0}
      limit={limit}
        pageNo={page}
        handlePageClick={handlePageClick}
      />
    
      {/* Render your content here */}
    </div>
                </div>
                <div className="overflow-x-auto rounded-t-xl  border border-gray-200">
                <p className='font-semibold m-3'>Total : {getAdvertise?.getBlogs?.count}</p>
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Date</td>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Image</td>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Title</td>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Description</td>
                                <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Delete</td>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && "Loading..."}
                            {getAdvertise?.getBlogs?.count > 0 && getAdvertise?.getBlogs?.data.map((item, index) => (
                                <tr key={index} className='hover:bg-gray-50'>
                                    <td className="py-2.5 text-sm px-4 border-b">{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                                    <td className="py-2.5 h-20 w-80 px-4 border-b">
                                        <img src={item.image} alt={item.title} />
                                    </td>
                                    <td className="py-2.5 text-sm px-4 border-b">{item?.title}</td>
                                    <td className="py-2.5 text-sm px-4 border-b">{item?.description}</td>
                                    <td className='py-2.5 text-sm px-4 border-b'>
                                        <button  className="px-4 py-1 border border-slate-900 text-slate-900 rounded-md hover:bg-slate-900 hover:text-white bg-white transition-colors duration-300" onClick={() => deleteAdvertise(item?.id)}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AdminAd;