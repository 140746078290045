import React from 'react'
import Header from '../Header/Header.js'
import Footer from '../Footer/Footer.js'
import deliveryCourierBikeCity from '../asset/img/delivery_courier_bike_city.jpg';
import useScrollToTop from '../components/useScrollToTop.js';


const SameDay = () => {

    useScrollToTop();
    
    return (
        <div>
            <Header />
            <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-24">
                <img
                    alt=""
                    src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
                    className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
                />
                <div
                    aria-hidden="true"
                    className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
                >
                    <div
                        style={{
                            clipPath:
                                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                        }}
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                    />
                </div>
                <div
                    aria-hidden="true"
                    className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
                >
                    <div
                        style={{
                            clipPath:
                                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                        }}
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                    />
                </div>
                <div className="mx-auto max-w-auto px-6 lg:px-8">
                    <div className="bg-white rounded-lg shadow-md p-6 md:flex md:items-center md:space-x-6 max-w-4xl mx-auto">
                        <div className="flex justify-center md:justify-start mb-4 md:mb-0">
                        <img src={deliveryCourierBikeCity} className="rounded-xl" alt="Delivery Courier Bike City" />
                        </div>
                        <div>
                            <h2 className="text-sm font-semibold mb-1 text-gray-800">EXCITING TIMES AHEAD</h2>
                            <h2 className="text-xl font-bold mb-4 text-gray-900">Coming Soon...</h2>
                            <h2 className="text-lg font-semibold mb-1 text-gray-900">2 Wheeler</h2>
                            <div className="text-sm flex items-center">
                                <span className="bg-gray-200 px-4 py-0.5 rounded-lg mr-2">
                                    20 kg
                                </span>
                            </div>
                            <p className="mt-4 text-lg font-semibold text-gray-900">
                                Starting from <span>₹48</span>
                            </p>
                            <p className="text-sm text-gray-500 mt-2">
                                Base fare is inclusive of 1.0 km distance & 25 minutes of order
                                time. Pricing may vary based on locality. Please note, road tax,
                                parking fee, etc., will be applicable over and above ride fare.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SameDay
