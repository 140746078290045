import React, { useState } from 'react';
import { FaChevronDown } from "react-icons/fa";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { FiMapPin } from "react-icons/fi";
import { HiArrowUp, HiArrowDown } from "react-icons/hi";
import { Link } from 'react-router-dom';
import { IoBriefcaseOutline } from "react-icons/io5";
import { ApolloConsumer } from '@apollo/client';
import { useModal } from '../Contex.js';
import { toast } from 'react-toastify';
import logo1 from '../asset/img/logo1.png';
import play1 from '../asset/img/play1.png';
import applef from '../asset/img/applef.png';
import { LuPlaneTakeoff } from "react-icons/lu";
import { RiRoadMapLine } from "react-icons/ri";
import { IoMdCall, IoMdMail } from "react-icons/io";
import { IoEarth } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}


const countries = [
    { name: "Australia" },
    { name: "Albania" },
    { name: "Algeria" },
    { name: "Andorra" },
    { name: "Angola" },
    { name: "Antigua and Barbuda" },
    { name: "Argentina" },
    { name: "Armenia" },
    { name: "Afghanistan" },
    { name: "Austria" },
    { name: "Azerbaijan" },
    { name: "Bahamas" },
    { name: "Bahrain" },
    { name: "Bangladesh" },
    { name: "Barbados" },
    { name: "Belarus" },
    { name: "Belgium" },
    { name: "Belize" },
    { name: "Benin" },
    { name: "Bhutan" },
    { name: "Bolivia" },
    { name: "Bosnia and Herzegovina" },
    { name: "Botswana" },
    { name: "Brazil" },
    { name: "Brunei" },
    { name: "Bulgaria" },
    { name: "Burkina Faso" },
    { name: "Burundi" },
    { name: "Cabo Verde" },
    { name: "Cambodia" },
    { name: "Cameroon" },
    { name: "Canada" },
    { name: "Central African Rep." },
    { name: "Chad" },
    { name: "Chile" },
    { name: "China" },
    { name: "Colombia" },
    { name: "Comoros" },
    { name: "Congo, Dem. Rep." },
    { name: "Congo, Republic" },
    { name: "Costa Rica" },
    { name: "Croatia" },
    { name: "Cuba" },
    { name: "Cyprus" },
    { name: "Czech Republic" },
    { name: "Denmark" },
    { name: "Djibouti" },
    { name: "Dominica" },
    { name: "Dominican Republic" },
    { name: "Ecuador" },
    { name: "Egypt" },
    { name: "El Salvador" },
    { name: "Equatorial Guinea" },
    { name: "Eritrea" },
    { name: "Estonia" },
    { name: "Eswatini" },
    { name: "Ethiopia" },
    { name: "Fiji" },
    { name: "Finland" },
    { name: "France" },
    { name: "Gabon" },
    { name: "Gambia" },
    { name: "Georgia" },
    { name: "Germany" },
    { name: "Ghana" },
    { name: "Greece" },
    { name: "Grenada" },
    { name: "Guatemala" },
    { name: "Guinea" },
    { name: "Guinea-Bissau" },
    { name: "Guyana" },
    { name: "Haiti" },
    { name: "Honduras" },
    { name: "Hungary" },
    { name: "Iceland" },
    { name: "India" },
    { name: "Indonesia" },
    { name: "Iran" },
    { name: "Iraq" },
    { name: "Ireland" },
    { name: "Israel" },
    { name: "Italy" },
    { name: "Jamaica" },
    { name: "Japan" },
    { name: "Jordan" },
    { name: "Kazakhstan" },
    { name: "Kenya" },
    { name: "Kiribati" },
    { name: "Korea, North" },
    { name: "Korea, South" },
    { name: "Kosovo" },
    { name: "Kuwait" },
    { name: "Kyrgyzstan" },
    { name: "Laos" },
    { name: "Latvia" },
    { name: "Lebanon" },
    { name: "Lesotho" },
    { name: "Liberia" },
    { name: "Libya" },
    { name: "Liechtenstein" },
    { name: "Lithuania" },
    { name: "Luxembourg" },
    { name: "Madagascar" },
    { name: "Malawi" },
    { name: "Malaysia" },
    { name: "Maldives" },
    { name: "Mali" },
    { name: "Malta" },
    { name: "Marshall Islands" },
    { name: "Mauritania" },
    { name: "Mauritius" },
    { name: "Mexico" },
    { name: "Micronesia" },
    { name: "Moldova" },
    { name: "Monaco" },
    { name: "Mongolia" },
    { name: "Montenegro" },
    { name: "Morocco" },
    { name: "Mozambique" },
    { name: "Myanmar" },
    { name: "Namibia" },
    { name: "Nauru" },
    { name: "Nepal" },
    { name: "Netherlands" },
    { name: "New Zealand" },
    { name: "Nicaragua" },
    { name: "Niger" },
    { name: "Nigeria" },
    { name: "North Macedonia" },
    { name: "Norway" },
    { name: "Oman" },
    { name: "Pakistan" },
    { name: "Palau" },
    { name: "Palestine" },
    { name: "Panama" },
    { name: "Papua New Guinea" },
    { name: "Paraguay" },
    { name: "Peru" },
    { name: "Philippines" },
    { name: "Poland" },
    { name: "Portugal" },
    { name: "Qatar" },
    { name: "Romania" },
    { name: "Russia" },
    { name: "Rwanda" },
    { name: "Saint Kitts and Nevis" },
    { name: "Saint Lucia" },
    { name: "Saint Vincent Grenadines" },
    { name: "Samoa" },
    { name: "San Marino" },
    { name: "Sao Tome and Principe" },
    { name: "Saudi Arabia" },
    { name: "Senegal" },
    { name: "Serbia" },
    { name: "Seychelles" },
    { name: "Sierra Leone" },
    { name: "Singapore" },
    { name: "Slovakia" },
    { name: "Slovenia" },
    { name: "Solomon Islands" },
    { name: "Somalia" },
    { name: "South Africa" },
    { name: "South Sudan" },
    { name: "Spain" },
    { name: "Sri Lanka" },
    { name: "Sudan" },
    { name: "Suriname" },
    { name: "Sweden" },
    { name: "Switzerland" },
    { name: "Syria" },
    { name: "Taiwan" },
    { name: "Tajikistan" },
    { name: "Tanzania" },
    { name: "Thailand" },
    { name: "Timor-Leste" },
    { name: "Togo" },
    { name: "Tonga" },
    { name: "Trinidad and Tobago" },
    { name: "Tunisia" },
    { name: "Turkey" },
    { name: "Turkmenistan" },
    { name: "Tuvalu" },
    { name: "Uganda" },
    { name: "Ukraine" },
    { name: "United Arab Emirates" },
    { name: "United Kingdom" },
    { name: "United States" },
    { name: "Uruguay" },
    { name: "Uzbekistan" },
    { name: "Vanuatu" },
    { name: "Vatican City" },
    { name: "Venezuela" },
    { name: "Vietnam" },
    { name: "Yemen" },
    { name: "Zambia" },
    { name: "Zimbabwe" }
];

const cities = [
    { city: 'Kolkata' },
    { city: 'Chennai' },
    { city: 'Ahmedabad' },
    { city: 'Chandigarh' },
    { city: 'Jaipur' },
    { city: 'Kochi' },
    { city: 'Coimbatore' },
    { city: 'Lucknow' },
    { city: 'Nagpur' },
    { city: 'Vadodara' },
    { city: 'Indore' },
    { city: 'Guwahati' },
    { city: 'Vizag' },
    { city: 'Surat' },
    { city: 'Dehradun' },
    { city: 'Noida' },
    { city: 'Ludhiana' },
    { city: 'Trichy' },
    { city: 'Vijayawada' },
    { city: 'Kanpur' },
    { city: 'Mysore' },
    { city: 'Nashik' },
    { city: 'Udaipur' },
    { city: 'Pondicherry' },
    { city: 'Agra' },
    { city: 'Aurangabad' },
    { city: 'Jalandhar' },
    { city: 'Kota' },
    { city: 'Madurai' },
    { city: 'Allahabad' },
    { city: 'Manipal' },
    { city: 'Amritsar' },
    { city: 'Bareilly' },
    { city: 'Meerut' },
    { city: 'Bhopal' },
    { city: 'Ooty' },
    { city: 'Bhubaneswar' },
    { city: 'Raipur' },
    { city: 'Bikaner' },
    { city: 'Rajkot' },
    { city: 'Kozhikode' },
    { city: 'Central Goa' },
    { city: 'Sirsa' },
    { city: 'Gwalior' },
    { city: 'Thrissur' },
    { city: 'Kharagpur' },
    { city: 'Tirupati' },
    { city: 'Tirupur' },
    { city: 'Vellore' },
    { city: 'Thiruvananthapuram' },
    { city: 'Warangal' },
    { city: 'Varanasi' },
    { city: 'Mangaluru' },
    { city: 'Patna' },
    { city: 'Ranchi' },
    { city: 'Faridabad' },
    { city: 'Guntur' },
    { city: 'Ujjain' },
    { city: 'Patiala' },
    { city: 'Karnal' },
    { city: 'Kakinada' },
    { city: 'Rajahmundry' },
    { city: 'Bilaspur' },
    { city: 'Bhilai' },
    { city: 'Anand' },
    { city: 'Bhavnagar' },
    { city: 'Jammu' },
    { city: 'Muktsar' },
    { city: 'Panipat' },
    { city: 'Dhanbad' },
    { city: 'Hubli' },
    { city: 'Belgaum' },
    { city: 'Jabalpur' },
    { city: 'Kolhapur' },
    { city: 'Solapur' },
    { city: 'Shillong' },
    { city: 'Cuttack' },
    { city: 'Aligarh' },
    { city: 'Salem' },
    { city: 'Jodhpur' },
    { city: 'Ajmer' },
    { city: 'Jhansi' },
    { city: 'Gorakhpur' },
    { city: 'Thanjavur' },
    { city: 'Erode' },
    { city: 'Nellore' },
    { city: 'Rourkela' },
    { city: 'Anantapur' },
    { city: 'Kurnool' },
    { city: 'Ahmednagar' },
    { city: 'Phagwara' },
    { city: 'Ambala' },
    { city: 'Ballari' },
    { city: 'Saharanpur' },
    { city: 'Tirunelveli' },
    { city: 'Bathinda' },
    { city: 'Mathura' },
    { city: 'Haridwar' },
    { city: 'Ratnagiri' },
    { city: 'Sangli' },
    { city: 'Amravati' },
    { city: 'Rishikesh' },
    { city: 'Nagercoil' },
    { city: 'KanyaKumari' },
    { city: 'Kadapa' },
    { city: 'Nizamabad' },
    { city: 'Shivamogga' },
    { city: 'Davanagere' },
    { city: 'Roorkee' },
    { city: 'Nanded' },
    { city: 'Rewa' },
    { city: 'Satna' },
    { city: 'Muzaffarpur' },
    { city: 'Valsad' },
    { city: 'Vapi' },
    { city: 'Chhapra' },
    { city: 'Dharamshala' },
    { city: 'Kollam' },
    { city: 'Silchar' },
    { city: 'Alappuzha' },
    { city: 'Sonipat' },
    { city: 'Rohtak' },
    { city: 'Mehsana' },
    { city: 'Kullu' },
    { city: 'Dhule' },
    { city: 'Dharwad' },
    { city: 'Latur' },
    { city: 'Vizianagaram' },
    { city: 'Khammam' },
    { city: 'Hampi' },
    { city: 'Nainital' },
    { city: 'Akola' },
    { city: 'Kalaburagi' },
    { city: 'Gaya' },
    { city: 'Muzaffarnagar' },
    { city: 'Dewas' },
    { city: 'Korba' },
    { city: 'Mussoorie' },
    { city: 'Jalgaon' },
    { city: 'Yamuna Nagar' },
    { city: 'Bhagalpur' },
    { city: 'Hapur' },
    { city: 'Morena' },
    { city: 'Hassan' },
    { city: 'Hisar' },
    { city: 'Godhra' },
    { city: 'Kolar' },
    { city: 'Rampur' },
    { city: 'Sitapur' },
    { city: 'Etawah' },
    { city: 'Porbandar' },
    { city: 'Nadiad' },
    { city: 'Sagar' },
    { city: 'Morbi' },
    { city: 'Chhindwara' },
    { city: 'Tumakuru' },
    { city: 'Singrauli' },
    { city: 'Thoothukudi' },
    { city: 'Katni' },
    { city: 'Khandwa' },
    { city: 'Eluru' },
    { city: 'Malappuram' },
    { city: 'Dibrugarh' },
    { city: 'Deoghar' },
    { city: 'Khanna' },
    { city: 'Bidar' },
    { city: 'Madikeri' },
    { city: 'Haldwani' },
    { city: 'Farrukhabad' },
    { city: 'Malegaon' },
    { city: 'Dindigul' },
    { city: 'Shahjahanpur' },
    { city: 'Beed' },
    { city: 'Junagadh' },
    { city: 'Asansol' },
    { city: 'Beawar' },
    { city: 'Kishangarh' },
    { city: 'Parbhani' },
    { city: 'Gondia' },
    { city: 'Ichalkaranji' },
    { city: 'Jalna' },
    { city: 'Yavatmal' },
    { city: 'Shivpuri' },
    { city: 'Moga' },
    { city: 'Abohar' },
    { city: 'Adoni' },
    { city: 'Madanapalle' },
    { city: 'Tiruvannamalai' },
    { city: 'Satara' },
    { city: 'Ambur' },
    { city: 'Karimnagar' },
    { city: 'Ratlam' },
    { city: 'Moradabad' },
    { city: 'Machilipatnam' },
    { city: 'Ongole' },
    { city: 'Kottayam' },
    { city: 'Darbhanga' },
    { city: 'Kurukshetra' },
    { city: 'Unnao' },
    { city: 'Bulandshahr' },
    { city: 'Durgapur' },
    { city: 'Siliguri' },
    { city: 'Pali' },
    { city: 'Tadepalligudem' },
    { city: 'Ramagundam' },
    { city: 'Mahbubnagar' },
    { city: 'Bhiwani' },
    { city: 'Cuddalore' },
    { city: 'Kaithal' },
    { city: 'Jagtial' },
    { city: 'Palakkad' },
    { city: 'Guna' },
    { city: 'Kumbakonam' },
    { city: 'Maunath Bhanjan' },
    { city: 'Baripada' },
    { city: 'Orai' },
    { city: 'Bhadrak' },
    { city: 'Batala' },
    { city: 'Firozpur' },
    { city: 'Barnala' },
    { city: 'Raigarh' },
    { city: 'Nagaon' },
    { city: 'Mainpuri' },
    { city: 'Balurghat' },
    { city: 'Giridih' },
    { city: 'Ghazipur' },
    { city: 'Jagdalpur' },
    { city: 'Vidisha' },
    { city: 'Dimapur' },
    { city: 'Shikohabad' },
    { city: 'Imphal' },
    { city: 'Lakhimpur' },
    { city: 'Rudrapur' },
    { city: 'Ambikapur' },
    { city: 'Rae Bareli' },
    { city: 'Tinsukia' },
    { city: 'Rajapalayam' },
    { city: 'Rajnandgaon' },
    { city: 'Kashipur' },
    { city: 'Ranibennur' },
    { city: 'Burhanpur' },
    { city: 'Bhadravati' },
    { city: 'Chittoor' },
    { city: 'Pudukkottai' },
    { city: 'Hardoi' },
    { city: 'Basti' },
    { city: 'Karaikkudi' },
    { city: 'Lalitpur' },
    { city: 'Hospet' },
    { city: 'Budaun' },
    { city: 'Neemuch' },
    { city: 'Pilibhit' },
    { city: 'Barshi' },
    { city: 'Sri Ganganagar' },
    { city: 'Wardha' },
    { city: 'Sehore' },
    { city: 'Bhimavaram' },
    { city: 'Hanumangarh' },
    { city: 'Pathankot' },
    { city: 'Puri' },
    { city: 'Fatehpur' },
    { city: 'Surendranagar Dudhrej' },
    { city: 'Jamnagar' },
    { city: 'Bhuj' },
    { city: 'Gandhidham' },
    { city: 'Bharuch' },
    { city: 'Navsari' },
    { city: 'Amreli' },
    { city: 'Palanpur' },
    { city: 'Bhilwara' },
    { city: 'Suratgarh' },
    { city: 'Sikar' },
    { city: 'Churu' },
    { city: 'Alwar' },
    { city: 'Bhiwadi' },
    { city: 'Bharatpur' },
    { city: 'Mount Abu' },
    { city: 'Bundi' },
    { city: 'Sawai Madhopur' },
    { city: 'Purulia' },
    { city: 'Bardhaman' },
    { city: 'Raniganj' },
    { city: 'Darjeeling' },
    { city: 'Jalpaiguri' },
    { city: 'Chittorgarh' },
    { city: 'Dholpur' },
    { city: 'Uluberia' },
    { city: 'Nabadwip' },
    { city: 'Bongaon' },
    { city: 'Kanchrapara' },
    { city: 'Habra' },
    { city: 'Firozabad' },
    { city: 'Nalgonda' },
    { city: 'Chandrapur' },
    { city: 'Bijapur' },
    { city: 'Bhusawal' },
    { city: 'Raichur' },
    { city: 'Bahraich' },
    { city: 'Azamgarh' },
    { city: 'Bahadurgarh' },
    { city: 'Jind' },
    { city: 'Rewari' },
    { city: 'Palwal' },
    { city: 'Hathras' },
    { city: 'Sambalpur' },
    { city: 'Banda' },
    { city: 'Hoshiarpur' },
    { city: 'Faridkot' },
    { city: 'Mandsaur' },
    { city: 'Suryapet' },
    { city: 'Adilabad' },
    { city: 'Narasaraopet' },
    { city: 'Faizabad' },
    { city: 'Tadpatri' },
    { city: 'Gonda' },
    { city: 'Mughalsarai' },
    { city: 'Medinipur' },
    { city: 'Nagda' },
    { city: 'Raiganj' },
    { city: 'Deoria City' },
    { city: 'Sultanpur' },
    { city: 'Shamli' },
    { city: 'Krishnanagar' },
    { city: 'Ballia' },
    { city: 'Guntakal' },
    { city: 'Miryalaguda' },
    { city: 'Etah' },
    { city: 'Berhampore' },
    { city: 'Gudivada' },
    { city: 'Haldia' },
    { city: 'Santipur' },
    { city: 'Basirhat' },
    { city: 'Udgir' },
    { city: 'Proddatur' },
    { city: 'Nagapattinam' },
    { city: 'Chikmagalur' },
    { city: 'Chandausi' },
    { city: 'Bhind' },
    { city: 'Mandya' },
    { city: 'Bagalkot' },
    { city: 'Nandurbar' },
    { city: 'Chitradurga' },
    { city: 'Osmanabad' },
    { city: 'Modinagar' },
    { city: 'Gadag-Betigeri' },
    { city: 'Hoshangabad' },
    { city: 'Jaunpur' },
    { city: 'Port Blair' },
    { city: 'Jorhat' },
    { city: 'Nandyal' },
    { city: 'Biharsharif' },
    { city: 'Buxar' },
    { city: 'Siwan' },
    { city: 'Dehri' },
    { city: 'Bettiah' },
    { city: 'Kishanganj' },
    { city: 'Saharsa' },
    { city: 'Hajipur' },
    { city: 'Motihari' },
    { city: 'Sasaram' },
    { city: 'Munger' },
    { city: 'Katihar' },
    { city: 'Arrah' },
    { city: 'Srikakulam' },
    { city: 'Begusarai' },
    { city: 'Neyveli' },
    { city: 'Waidhan' },
    { city: 'Markapur' },
    { city: 'Chikkaballapur' },
    { city: 'Bhatkal' },
    { city: 'Gokak' },
    { city: 'Itarsi' },
    { city: 'Dhar' },
    { city: 'Chalisgaon' },
    { city: 'Thiruvallur' },
    { city: 'Namakkal' },
    { city: 'Dharmapuri' },
    { city: 'Bhandara' },
    { city: 'Virudhunagar' },
    { city: 'Siddipet' },
    { city: 'Gadwal' },
    { city: 'Bodhan-Rural' },
    { city: 'Kamareddy' },
    { city: 'Jhunjhunu' },
    { city: 'Kapurthala' },
    { city: 'Sangrur' },
    { city: 'Gurdaspur' },
    { city: 'Ramgarh' },
    { city: 'Bantwal' },
    { city: 'Doddaballapura' },
    { city: 'Buldana' },
    { city: 'Karad' },
    { city: 'Krishnagiri' },
    { city: 'Tiptur' },
    { city: 'Bhadrachalam' },
    { city: 'Mancherial' },
    { city: 'Balrampur' },
    { city: 'Bharabanki' },
    { city: 'Malout' },
    { city: 'Fatehgarh Sahib' },
    { city: 'Ropar' },
    { city: 'Nangal' },
    { city: 'Narnaul' },
    { city: 'Naraingarh' },
    { city: 'Himmatnagar' },
    { city: 'Dausa' },
    { city: 'Jahanabad' },
    { city: 'Samastipur' },
    { city: 'Purnea' },
    { city: 'Berhampur' },
    { city: 'Malda' },
    { city: 'Tuni' },
    { city: 'Puttur' },
    { city: 'Rayachoty' },
    { city: 'Nirmal' },
    { city: 'Mirzapur' },
    { city: 'Tanuku' },
    { city: 'Dahod' },
    { city: 'Barmer' },
    { city: 'Gangapur City' },
    { city: 'Mandi Gobindgarh' },
    { city: 'Tarn Taran Sahib' },
    { city: 'Nakodar' },
    { city: 'Ankleshwar' },
    { city: 'Vyara' },
    { city: 'Bardoli' },
    { city: 'Halol' },
    { city: 'Bijnor' },
    { city: 'Sangamner' },
    { city: 'Baramati' },
    { city: 'Betul' },
    { city: 'Chhatarpur' },
    { city: 'Balaghat' },
    { city: 'Sivakasi' },
    { city: 'Viluppuram' },
    { city: 'Ramanathapuram' },
    { city: 'Sirsi' },
    { city: 'Theni' },
    { city: 'Karur' },
    { city: 'Karwar' },
    { city: 'Sindhanur' },
    { city: 'Kannur' },
    { city: 'Noida 1' },
    { city: 'Thiruvalla' },
    { city: 'Thodupuzha' },
    { city: 'Kadiri' },
    { city: 'Kavali' },
    { city: 'Tezpur' },
    { city: 'Kayamkulam' },
    { city: 'Kottarakkara' },
    { city: 'Mandi Dabwali' },
    { city: 'Fatehabad' },
    { city: 'Jagraon' },
    { city: 'Mansa' },
    { city: 'Pinjore City' },
    { city: 'Fazilka' },
    { city: 'Baddi' },
    { city: 'Solan' },
    { city: 'Daltonganj' },
    { city: 'Balangir' },
    { city: 'Kothagudem' },
    { city: 'Hansi' },
    { city: 'Aurangabad_Bihar' },
    { city: 'Gopalganj' },
    { city: 'Jharsuguda' },
    { city: 'Tohana' },
    { city: 'Jhalawar' },
    { city: 'Sivasagar' },
    { city: 'Bagdogra' },
    { city: 'Kendrapada' },
    { city: 'Mallapuram' },
    { city: 'Veraval' },
    { city: 'Daman' },
    { city: 'Chiplun' },
    { city: 'Silvassa' },
    { city: 'Lonavla' },
    { city: 'Bongaigaon' },
    { city: 'Golaghat' },
    { city: 'Duliajan' },
    { city: 'Bolpur' },
    { city: 'Madhubani' },
    { city: 'Balasore' },
    { city: 'Palampur' },
    { city: 'Kotdwar' },
    { city: 'Koppal' },
    { city: 'Chikhli' },
    { city: 'Dahanu' },
    { city: 'Itanagar' },
    { city: 'Rangpo' },
    { city: 'Aizawl' },
    { city: 'Gangtok' },
    { city: 'Mayiladuthurai' },
    { city: 'Kannauj' },
    { city: 'Cooch Behar' },
    { city: 'Palani' },
    { city: 'Bilimora' },
    { city: 'Boisar' },
    { city: 'Kohima' },
    { city: 'Naharlagun' },
    { city: 'Dumka' },
    { city: 'Rajsamand' },
    { city: 'Gauriganj' },
    { city: 'Bodinayakanur' },
    { city: 'Bhawanipatna' },
    { city: 'Baran' },
    { city: 'Narsinghpur' },
    { city: 'Kovilpatti' },
    { city: 'Pusad' },
    { city: 'Kendujhar' },
    { city: 'Manali' },
    { city: 'Diu' },
    { city: 'Khamgaon' },
    { city: 'Ramanagara' },
    { city: 'Alipurduar' },
    { city: 'Almora' },
    { city: 'Jhargram' },
    { city: 'Arambagh' },
    { city: 'Bhadohi' },
    { city: 'Tenkasi' },
    { city: 'Srivilliputhur' },
    { city: 'Chidambaram' },
    { city: 'Rajgarh' },
    { city: 'Pratapgarh' },
    { city: 'Washim' },
    { city: 'Raghunathpur' },
    { city: 'Suri' },
    { city: 'Kadayanallur' },
    { city: 'Thiruvarur' },
    { city: 'Ranaghat-WB' },
    { city: 'Wayanad' },
    { city: 'Perambalur' },
    { city: 'Paramakudi' },
    { city: 'Bela Pratapgarh' },
    { city: 'Mahoba' },
    { city: 'Sitamarhi' },
    { city: 'Chakdaha' },
    { city: 'Khalilabad' },
    { city: 'Pattukkottai' },
    { city: 'Tindivanam' },
    { city: 'Tiruttani' },
    { city: 'Gangarampur' },
    { city: 'Dharapuram' },
    { city: 'Arakkonam' },
    { city: 'Sirkali' },
    { city: 'Mettupalayam' },
    { city: 'Digboi' },
    { city: 'Biswanath Chariali' },
    { city: 'Nalbari' },
    { city: 'Shirdi city' },
    { city: 'Mukerian' },
    { city: 'Hosur' },
    { city: 'Palakollu' },
    { city: 'Ravulapalem' },
    { city: 'Barh' },
    { city: 'Palghar' },
    { city: 'Kushalnagar' },
    { city: 'Dungarpur' },
    { city: 'Chaibasa' },
    { city: 'Haveri' },
    { city: 'Karaikal' },
    { city: 'Jjajjar' },
    { city: 'Kokrajhar' },
    { city: 'Rangia' },
    { city: 'Hamirpur' },
    { city: 'Una' },
    { city: 'Sulthan Bathery' },
    { city: 'Rayagada' },
    { city: 'Paradeep' },
    { city: 'Mandapeta' },
    { city: 'Jamui' },
    { city: 'Pilkhuwa' },
    { city: 'Parvathipuram' },
    { city: 'Ambajogai' },
    { city: 'Araria' },
    { city: 'North Lakhimpur' },
    { city: 'Rajampet' },
    { city: 'Udumalaipettai' },
    { city: 'Tirupattur' },
    { city: 'Hojai' },
    { city: 'Khagaria' },
    { city: 'Dasuya' },
    { city: 'Gudur' },
    { city: 'Sullurpeta' },
    { city: 'Piler' },
    { city: 'SankaranKoil' },
    { city: 'Nabha' },
    { city: 'LPU - Phagwara' },
    { city: 'Jangipur' },
    { city: 'Roha' }
];




const Footer = () => {
    const { setModalOpen } = useModal();
    const [showTooltip, setShowTooltip] = useState(false);
    // const currentYear = new Date().getFullYear();
    const token = localStorage.getItem('token');

    const signOut = (client) => {
        localStorage.clear()
        sessionStorage.clear();
        client.resetStore();
        toast.success("user logged out successfully!", {
            autoClose: 2000
        })
    };

    const handleClick = () => {
        setShowTooltip(!showTooltip);
    };

    return (
        <div style={{ backgroundColor: "#fafafa" }}>
            <div className="lg:flex lg:items-center lg:justify-between mx-auto max-w-auto px-6 lg:px-8 py-10">
                <div className="min-w-0 flex-1">
                    <div className="flex lg:ml-0 mb-4">
                        <img src={logo1} className="h-8 w-auto" alt="Logo" />
                        <span className="self-center text-2xl font-bold ms-1">UK Logistics</span>
                    </div>
                    <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                        <div className="mt-2 flex items-center text-sm text-gray-500 cursor-pointer">
                            <IoBriefcaseOutline className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            Digital Services
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500 cursor-pointer">
                            <FiMapPin className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            Mumbai (Maharashtra)
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500 cursor-pointer">
                            <HiArrowUp className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            Free Pick-up at your doorstep
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500 cursor-pointer">
                            <HiArrowDown className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            Delivery at your doorstep
                        </div>
                    </div>
                </div>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <span className="hidden sm:block">
                        <Link to="/accordionhelp">
                            <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                Help
                            </button>
                        </Link>
                    </span>
                    <span className="ml-3 hidden sm:block">
                        <Link to="/allorders">
                            <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                All Courier
                            </button></Link>
                    </span>
                    <span className="sm:ml-3">
                        <Link to="/documentshipping">
                            <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-slate-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Courier Now
                            </button></Link>
                    </span>
                    <Menu as="div" className="relative ml-3 sm:hidden">
                        <MenuButton className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                            More
                            <FaChevronDown className="-mr-1 ml-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </MenuButton>
                        <Transition
                            enter="transition ease-out duration-200"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <MenuItems className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <MenuItem>
                                    {({ active }) => (
                                        <Link
                                            to="/allorders"
                                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                        >
                                            All Courier
                                        </Link>
                                    )}
                                </MenuItem>
                                <MenuItem>
                                    {({ active }) => (
                                        <Link
                                            to="/accordionhelp"
                                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                        >
                                            Help
                                        </Link>
                                    )}
                                </MenuItem>
                            </MenuItems>
                        </Transition>
                    </Menu>
                </div>
            </div>
            <div className='mx-auto max-w-auto px-6 lg:px-8 pt-1 pb-10'>
                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 pb-10 gap-x--0 gap-y-10 text-sm'>
                    <ul className='mt-6 space-y-6 sm:mt-4 sm:space-y-4'>
                        <li className='font-medium text-gray-900'>Ship now</li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/documentshipping' state={{ key: false }}>Document</Link></li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/documentshipping' state={{ key: true }}>0 to 30Kg</Link></li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/sameday'>Same day</Link></li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/foreignair'>Foreign Air</Link></li>
                    </ul>
                    <ul className='mt-6 space-y-6 sm:mt-4 sm:space-y-4'>
                        <li className='font-medium text-gray-900'>Ship Calculation</li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/calculatorcourier' state={{ keyy: false }}>Document</Link></li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/calculatorcourier' state={{ keyy: true }}>0 to 30Kg</Link></li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/sameday'>Same day</Link></li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/foreignair'>Foreign Air</Link></li>
                    </ul>
                    <ul className='mt-6 space-y-6 sm:mt-4 sm:space-y-4'>
                        <li className='font-medium text-gray-900'>Couriers</li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/allOrders' state={{ data: 'All Courier' }}>All Couriers</Link></li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/allOrders' state={{ data: 'To me' }}>To me</Link></li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/allOrders' state={{ data: 'From me' }}>From me</Link></li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/trackcourier'>Find Courier</Link></li>
                    </ul>
                    <ul className='mt-6 space-y-6 sm:mt-4 sm:space-y-4'>
                        <li className='font-medium text-gray-900'>Account & Lists</li>

                        <li className='hover:text-gray-800 text-gray-500'>
                            <Link to='/accordionhelp'>Help</Link>
                        </li>

                        {!token && (
                            <>
                                <li className='hover:text-gray-800 text-gray-500'>
                                    <Link to='/signup'>Sign in</Link>
                                </li>
                                <li className='hover:text-gray-800 text-gray-500'>
                                    <Link to='/signup'>Create Account</Link>
                                </li>
                            </>
                        )}

                        {token && (
                            <li className='hover:text-gray-800 text-gray-500'>
                                <ApolloConsumer >
                                    {client => (
                                        <Link to='/signup' onClick={() => signOut(client)} >
                                            Log out
                                        </Link>
                                    )}
                                </ApolloConsumer>
                            </li>
                        )}

                        <li className='hover:text-gray-800 text-gray-500'>
                            <Link to='/packguidlines'>Packaging Guidelines</Link>
                        </li>
                        <li className='hover:text-gray-800 text-gray-500'>
                            <Link to='/retrictedItem'>Restricted Items</Link>
                        </li>
                    </ul>

                    <ul className='mt-6 space-y-6 sm:mt-4 sm:space-y-4'>
                        <li className='font-medium text-gray-900'>My Activities</li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/shippinglabel'>Shipping Label</Link></li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/anotherhelp'>Enquiry Now</Link></li>
                        <li className='hover:text-gray-800 text-gray-500 cursor-pointer' onClick={(e) => { e.preventDefault(); setModalOpen(true) }}>Edit Profile</li>
                    </ul>
                    <ul className='mt-6 space-y-6 sm:mt-4 sm:space-y-4'>
                        <li className='font-medium text-gray-900'>Policies</li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/privacyPolicy'>Privacy Policy</Link></li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/termsAndConditions'>Terms & Conditions</Link></li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/cookiespolicy'>Cookie Policy</Link></li>
                        <li className='hover:text-gray-800 text-gray-500'><Link to='/fraudDisclaimer'>Fraud Disclaimer</Link></li>
                    </ul>
                </div>

                <div className='flex flex-col sm:flex-row space-y-6 md:space-y-0 mt-3 mb-7 sm:space-x-6'>
                    <p className='text-sm text-gray-900'>
                        <a href="mailto:uk@uk-logistic.com" className='flex items-center'>
                            <span className='text- font-bold mr-2'>Raise a Query : </span>
                            uk@uk-logistic.com
                        </a>
                    </p>

                    <p className='text-sm text-gray-900'>
                        <a href="tel:+17722444094" className='flex items-center'>
                            <IoEarth className="mr-2 text-xl text-slate-900" />
                            (+1) 772 244 4094 (USA)
                        </a>
                    </p>

                    <p className='text-sm text-gray-900'>
                        <a href="tel:+17722444094" className='flex items-center'>
                            <IoEarth className="mr-2 text-xl text-slate-900" />
                            (+971) 12-345-6466 (Dubai)
                        </a>
                    </p>

                    <p className='text-sm text-gray-900'>
                        <a href="tel:+9102269408494" className='flex items-center'>
                            <IoMdCall className="mr-2 text-xl text-slate-900" />
                            +91 022 6940 8494 (Mumbai)
                        </a>
                    </p>

                    <p className='text-sm text-gray-900'>
                        <a href="tel:+917096964646" className='flex items-center'>
                            <IoMdCall className="mr-2 text-xl text-slate-900" />
                            070 9696 4646 (Support)
                        </a>
                    </p>
                </div>


                <div className='mb-10'>
                    <p className='font-bold md:mb-2 mb-4'>Offices : </p>
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 space-y-4 md:space-y-2 text-sm'>
                        <div className='flex cursor-pointer hover:text-blue-600 items-center space-x-2'>
                            <p className='text-slate-400'><FaLocationDot /></p>
                            <p> International Courier Office - Surat (Gujarat)</p>
                        </div>
                        <div className='flex cursor-pointer hover:text-blue-600 items-center space-x-2'>
                            <p className='text-slate-400'><FaLocationDot /></p>
                            <p> International Courier Office - Ahmedabad (Gujarat)</p>
                        </div>
                        <div className='flex cursor-pointer hover:text-blue-600 items-center space-x-2'>
                            <p className='text-slate-400'><FaLocationDot /></p>
                            <p> International Courier Office - Mumbai (Maharashtra)</p>
                        </div>
                        <div className='flex cursor-pointer hover:text-blue-600 items-center space-x-2'>
                            <p className='text-slate-400'><FaLocationDot /></p>
                            <p> International Courier Office - Pune (Maharashtra)</p>
                        </div>
                        <div className='flex cursor-pointer hover:text-blue-600 items-center space-x-2'>
                            <p className='text-slate-400'><FaLocationDot /></p>
                            <p> International Courier Office - Delhi (Delhi)</p>
                        </div>
                        <div className='flex cursor-pointer hover:text-blue-600 items-center space-x-2'>
                            <p className='text-slate-400'><FaLocationDot /></p>
                            <p> International Courier Office - Kolkata (West Bengal)</p>
                        </div>
                        <div className='flex cursor-pointer hover:text-blue-600 items-center space-x-2'>
                            <p className='text-slate-400'><FaLocationDot /></p>
                            <p> International Courier Office - Chennai (Tamil Nadu)</p>
                        </div>
                        <div className='flex cursor-pointer hover:text-blue-600 items-center space-x-2'>
                            <p className='text-slate-400'><FaLocationDot /></p>
                            <p> International Courier Office - Bangalore (Karnataka)</p>
                        </div>
                        <div className='flex cursor-pointer hover:text-blue-600 items-center space-x-2'>
                            <p className='text-slate-400'><FaLocationDot /></p>
                            <p> International Courier Office - Hyderabad (Telangana)</p>
                        </div>

                    </div>

                </div>

                {/* <div className='flex'> */}


                <div className=" justify-between ">
                    {/* Left Side: Indian Cities */}
                    {/* <div className="flex-1  ga"> */}
                    <div className="flex mb-2 font-semibold">
                        <LuPlaneTakeoff className="text-2xl mb-2 me-2 text-blue-950" /> {/* Icon for International Courier */}
                        <span>International Courier</span>
                    </div>

                    <div className='grid lg:grid-cols-6 md:grid-cols-5 grid-cols-2 space-y-2'>
                        {countries.map((country) => (
                            <Link to='/foreignair' key={country.name}><div

                                className="hover:text-gray-800 text-sm cursor-pointer text-gray-500"
                            >
                                {country.name}
                            </div></Link>
                        ))}
                    </div>
                    <div className="flex mb-2 mt-10 font-semibold">
                        <RiRoadMapLine className="text-2xl mb-2 me-2" /> {/* Icon for Domestic Courier */}
                        <span>Domestic Courier</span>
                    </div>

                    <div className='grid lg:grid-cols-6 md:grid-cols-5 grid-cols-2 space-y-2'>
                        {cities.map((citie) => (
                            <Link to='/documentshipping' key={citie.city}><div

                                className="hover:text-gray-800 text-sm cursor-pointer text-gray-500"
                            >
                                {citie.city}
                            </div></Link>
                        ))}
                    </div>
                    {/* </div> */}

                    {/* Right Side: International Countries */}

                </div>
                {/* <div className='flex'>
                        <div className=" grid grid-cols-3 gap-4 ">
                            {countries.map((country, index) => (
                                <div
                                    key={index}
                                    className="hover:text-gray-800 text-sm cursor-pointer text-gray-500"
                                >
                                    {country.name}
                                </div>
                            ))}
                        </div>
                    </div> */}




                {/* </div> */}







                <div className="relative inline-block">
                    <div className='md:flex space-y-6 md:space-y-0 gap-x-6 mt-10 flex-none'>
                        <img src={play1} alt="" className='h-16 md:h-14 cursor-pointer' onClick={handleClick} />
                        <img src={applef} className='h-16 md:h-14 cursor-pointer' alt="" onClick={handleClick} />
                    </div>
                    {showTooltip && (
                        <div className="absolute mt-4 top-full w-48 bg-gray-800 text-white text-sm rounded-lg shadow-lg p-2">
                            Our application is currently undergoing essential maintenance to bring you new features, improvements, and a better overall experience.
                        </div>
                    )}
                </div>

                <p className='border mt-12'></p>
                <p className='text-md font-semibold text-gray-900 mt-9'>Information Security Policy</p>
                <p className='text-sm mt-1 text-gray-500'>UK Logistics is committed to safeguarding the confidentiality, integrity and availability of all physical and electronic information assets of the organization.
                    We ensure that the regulatory, operational and contractual requirements are fulfilled.</p>



                {/* <p className='mt-3 text-lg font-bold text-slate-800'><a href="mailto:uk@uk-logistic.com"><span className='text-slate-900 font-bold'>Mail to:</span> uk@uk-logistic.com</a></p> */}
                <p className='text-sm text-gray-500 mt-6'>This website and its content, including text, images, graphics, and other material, are protected by copyright law. Unauthorized use, reproduction, or distribution of this material without prior written permission from UK Logistics is strictly prohibited.

                </p>
                <p className='text-sm mt-1 text-gray-500'>© {new Date().getFullYear()} UK Logistics. All rights reserved.</p>


            </div>
        </div>

    );
};

export default Footer;
