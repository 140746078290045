import { gql } from '@apollo/client';

export const ADD_IMG_VID_OPTION = gql`
  mutation AddImgVidOption($input: imgVidOption) {
    addImgVidOption(input: $input) {
      id
      image
      video
    }
  }
`;

export const UPDATE_IMG_VID_OPTION = gql`
  mutation UpdateImgVidOption($input: imgVidOption) {
    updateImgVidOption(input: $input) {
      id
      image
      video
    }
  }
`;

export const DELETE_IMG_VID_OPTION = gql`
  mutation DeleteImgVidOption($id: ID) {
    deleteImgVidOption(id: $id)
  }
`;

export const GET_IMG_VID_OPTIONS = gql`
  query GetImgVidOptions($page: Int, $limit: Int) {
    getImgVidOption(page: $page, limit: $limit) {
      id
      image
      video
    }
  }
`;