import React, { useEffect } from 'react'
import box2Image from '../asset/img/box2.jpg';
import i3Image from '../asset/img/i3.avif';


const product = {
  name: `Get your shipment picked up
from your doorstep`,
  price: '$192',
  href: '#',
  breadcrumbs: [
    { id: 1, name: 'Men', href: '#' },
    { id: 2, name: 'Clothing', href: '#' },
  ],
  colors: [
    { name: 'White', class: 'bg-white', selectedClass: 'ring-gray-400' },
    { name: 'Gray', class: 'bg-gray-200', selectedClass: 'ring-gray-400' },
    { name: 'Black', class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
  ],
  sizes: [
    { name: 'XXS', inStock: false },
    { name: 'XS', inStock: true },
    { name: 'S', inStock: true },
    { name: 'M', inStock: true },
    { name: 'L', inStock: true },
    { name: 'XL', inStock: true },
    { name: '2XL', inStock: true },
    { name: '3XL', inStock: true },
  ],
  description:
    `Trusted partner for small businesses, home or office, large marketplaces and anyone else looking to send parcels across the country efficiently we ensure that your shipments reach more than 99.5% of India's population reliably and effectively.`,
  highlights: [
    'Tech-enabled pick-up and delivery services to whole of India',
    'Speed and Reliability',
    'Supported by 150+ gateways, hubs and fulfillment centers across 200+ cities',
    'Multi-modal network (Air + Surface)',
    'digital payment solutions',
    'Dedicated Fleet Network',
    'Technology and Automation',
  ],
}

const Typesix = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // });
  return (
    <div>
      <div className="bg-white">
        <div className="pt-12 md:pt-6">
          <div className="mx-auto max-w-auto px-5 sm:px-11 lg:px-8 pb-4  lg:grid lg:max-w-auto lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:pb-8 lg:pt-6">
            <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.name}</h1>
            </div>

            <div className=" lg:row-span-3">

              <div className=" lg:grid lg:grid-cols-1">
                <div className="aspect-h-2 aspect-w-3 my-4 overflow-hidden rounded-lg hover:opacity-75">
                  <img
                    src={box2Image}
                    className="h-52 w-full object-cover object-center"
                    alt="Box Image"
                  />
                </div>
                <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg hover:opacity-75">
                  <img
                    src={i3Image}
                    className="h-52 w-full object-cover object-center"
                    alt="Image i3"
                  />
                </div>

              </div>
            </div>

            <div className="py-1 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-0 xl:pb-6  lg:pr-8 lg:pt-6">

              <div>
                <h3 className="sr-only">Description</h3>

                <div className="space-y-6 pt-3">
                  <p className="text-base text-gray-900">{product.description}</p>
                </div>
              </div>

              <div className="mt-5">
                <h3 className="text-sm font-medium text-gray-900">Shipping Services</h3>

                <div className="mt-4">
                  <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                    {product.highlights.map((highlight) => (
                      <li key={highlight} className="text-gray-400">
                        <span className="text-gray-600">{highlight}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="mt-4">
                <h2 className="text-sm font-medium text-gray-900">Ship Now</h2>

                <div className="mt-4 space-y-1">
                  <p className="text-sm text-gray-600">● Send your parcel from the comfort of your home or office.</p>
                  <p className="text-sm text-gray-600">● Ship and track from the comfort of your home</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Typesix
