import React, { useEffect } from 'react'
import { RxDimensions } from "react-icons/rx";
import { RiWeightLine } from "react-icons/ri";
import { BsBox } from "react-icons/bs";
import { IoWaterOutline } from "react-icons/io5";
import useScrollToTop from '../components/useScrollToTop.js';


const Typefour = () => {
    useScrollToTop();

    return (
        <div>
            <div className="relative isolate overflow-hidden mb-8  sm:py-6 lg:py-2">
                <div className="mx-auto max-w-auto px-6 lg:px-8 mt-1">
                    <h2 className="text-2xl font-bold tracking-tight  text-gray-900 mb-9 ml-0 lg:ml-0 md:ml-6">Packaging Guidlines</h2>
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2">
                        <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-1">
                            <div className="flex flex-col items-start border border-gray-300 hover:bg-gray-50 cursor-pointer rounded-lg p-5">
                                <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                    <RxDimensions className="h-6 w-6 text-black" aria-hidden="true" />
                                </div>
                                <dt className="mt-4 font-semibold text-black">Dimensions</dt>
                                <dd className="mt-2 text-base text-gray-800">
                                    Helps in selection of appropriate size of boxes/flyers. Minimize inventory & cost
                                </dd>
                            </div>
                            <div className="flex flex-col items-start border border-gray-300 hover:bg-gray-50 cursor-pointer rounded-lg p-5">
                                <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                    <RiWeightLine className="h-6 w-6 text-black" aria-hidden="true" />
                                </div>
                                <dt className="mt-4 font-semibold text-black">Weight</dt>
                                <dd className="mt-2 text-base text-gray-800">
                                    Optimize for the minimum strength required for box. Ensures safety of item
                                </dd>
                            </div>
                        </dl>
                        <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
                            <div className="flex flex-col items-start border border-gray-300 hover:bg-gray-50 cursor-pointer rounded-lg p-5">
                                <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                    <BsBox className="h-6 w-6 text-black" aria-hidden="true" />
                                </div>
                                <dt className="mt-4 font-semibold text-black">Fragility</dt>
                                <dd className="mt-2 text-base text-gray-800">
                                    Helps select appropriate box strength. Helps in optimizing the cushioning.
                                </dd>
                            </div>
                            <div className="flex flex-col items-start border border-gray-300 hover:bg-gray-50 cursor-pointer rounded-lg p-5">
                                <div className="rounded-md bg-black/5 p-2 ring-1 ring-black/10">
                                    <IoWaterOutline className="h-6 w-6 text-black" aria-hidden="true" />
                                </div>
                                <dt className="mt-4 font-semibold text-black">
                                    Physical State</dt>
                                <dd className="mt-2 text-base text-gray-800">
                                    Identifies the need for any special requirements for the protection of item
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Typefour
