import { gql } from "@apollo/client";

export const ADD_ADVERTISE = gql`
mutation Mutation($input: blog) {
  addBlog(input: $input) {
    status
    message
    blog {
      id
      title
      image
      description
      isActive
      createdAt
    }
  }
}
`

export const DELETE_ADvertise = gql`
mutation DeleteBlog($deleteBlogId: ID) {
  deleteBlog(id: $deleteBlogId)
}
`