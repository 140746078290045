import { gql } from "@apollo/client"

export const RECEIVER_DATA = gql`
mutation Mutation($input: ReceiverInput!) {
    insertUpdateReceiver(input: $input) {
      id
      name
      phone
      address1
      stateId
      city
      warehouse_name
      zipCode
      isDeleted
      isActive
      customerId
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
  
`
export const DELETERECEIVER = gql`
mutation DeleteReceiver($deleteReceiverId: ID!) {
  deleteReceiver(id: $deleteReceiverId) {
    id
    name
    phone
    address1
    stateId
    city
    zipCode
    isDeleted
    isActive
    customerId
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`