import React from 'react';
import { FaImages } from 'react-icons/fa';

const NoDataFound = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <FaImages className="text-gray-400 text-4xl mb-4" />
      <h1 className="text-xl font-semibold text-gray-800 mb-2">No Data Found</h1>
      <p className="text-gray-600 text-sm mb-6">The data source is empty or unavailable..</p>
    </div>
  );
};

export default NoDataFound;
