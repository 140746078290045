// ===========================LIVE URL==================
export const API_URL = 'https://api.uk-logistic.com';
export const RETURN_URL= 'https://uk-logistic.com/SuccessPayment'

export const CASHFREE_URL = 'https://api.cashfree.com/pg/orders'
export const CASHFREE_CLIENT_ID='733355b7dd879cc9b8ef9d45d5553337'
export const CASHFREE_CLIENT_SECRET ='cfsk_ma_prod_f703227a8c97923307436645926eed92_63b4651e'

// ===================demo==========================

// export const API_URL='http://localhost:8000'
// export const RETURN_URL= 'http://localhost:3000/SuccessPayment'
// export const CASHFREE_URL = 'https://sandbox.cashfree.com/pg/orders'
// export const CASHFREE_CLIENT_ID='TEST10200768f55668686eb2dc89466186700201'
// export const CASHFREE_CLIENT_SECRET ='cfsk_ma_test_1bccf47f2c363eada9f504086d1ca65e_125e43c0'
