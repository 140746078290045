import React, { useEffect } from 'react'
import createImage from '../asset/img/create.jpg';
import printImage from '../asset/img/print.jpg';
import pickupImage from '../asset/img/pickup.jpg';
// import deliveredImage from '../asset/img/delivered.jpg';
import ukv from '../asset/img/ukvaan.webp';

const callouts = [
    {
        name: 'Ship Now',
        description: 'Work from home accessories',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-02-edition-01.jpg',
        imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
        href: '#',
    },
    {
        name: 'Estimate Price?',
        description: 'Journals and note-taking',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-02-edition-02.jpg',
        imageAlt: 'Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.',
        href: '#',
    },
    {
        name: 'Travel',
        description: 'Daily commute essentials',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-02-edition-03.jpg',
        imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
        href: '#',
    },
]

const products = [
    {
        id: 1,
        name: '1. Create Courier',
        href: '#',
        des: 'Get doorstep pickup for parcels from your location. Book couriers for yourself or your loved ones online from our website or app',
        imageSrc: createImage,
        imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
    },
    {
        id: 2,
        name: '2.Print, paste shipment label',
        href: '#',
        des: ' You need to print and paste the shipment label.then Our field executive will carry your shipment.',
        imageSrc: printImage,
        imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
        id: 3,
        name: '3. We arrive at your doorstep',
        href: '#',
        des: 'Our partners reach your location within 24 hours of placing the order.',
        imageSrc: pickupImage,
        imageAlt: 'Person using a pen to cross a task off a productivity paper card.',
    },
    {
        id: 4,
        name: '4. Sit back and relax',
        href: '#',
        des: 'Your order will be delivered to the chosen destination. Track it from our website or app',
        imageSrc: ukv,
        imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
]


const Typefive = () => {
    return (
        <>

            <div className="bg-white">
                <div className="mx-auto max-w-auto px-4 py-16 sm:px-6 sm:py-8 lg:max-w-auto lg:px-8">
                    <h2 className="sr-only">Products</h2>

                    <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 xl:gap-x-8">
                        {products.map((product) => (
                            <a key={product.id} href={product.href} className="group">
                                <p className="text-xl mb-4 font-bold lg:text-sm xl:text-xl text-gray-900">{product.name}</p>
                                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                                    <img
                                        alt={product.imageAlt}
                                        src={product.imageSrc}
                                        className="h-full w-full object-cover object-center group-hover:opacity-75"
                                    />
                                </div>
                                <p className="mb-4 mt-2 text-sm text-slate-700">{product.des}</p>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Typefive
