import { gql } from "@apollo/client";

export const GET_GENERAL_SETTING = gql`
query GetGeneralSettings {
  getGeneralSettings {
    id
    prohibitedList
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`

export const INSERT_UPDATE_GENERALSETTING = gql`
mutation InsertUpdateGeneralSettings($input: GeneralSettingsInput!) {
  insertUpdateGeneralSettings(input: $input) {
    id
    prohibitedList
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`