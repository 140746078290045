import { gql } from "@apollo/client";

export const GET_ADVERTISE = gql`
query Query($page: Int, $limit: Int,$sort: userSort) {
  getBlogs(page: $page, limit: $limit,sort: $sort) {
    count
    data {
      id
      title
      image
      description
      readStatus
      isActive
      createdAt
    }
  }
}
`

export const UPDATE_NOTIFICATION_STATUS = gql`
mutation Mutation($id: [ID]) {
  updateReadStatus(id: $id)
}
`