import { gql } from "@apollo/client";

export const GET_ORDERS = gql`
query GetOrders($page: Int, $limit: Int, $filter: String, $sort: orderSort, $from: String, $to: String, $orderStatus: String, $customerId :String,$tag:String) {
  getOrders(page: $page, limit: $limit, filter: $filter, sort: $sort, from: $from, to: $to, orderStatus: $orderStatus,customerId: $customerId,tag: $tag) {
    count
    data {
      id
      customerId
      order_number
      pickupAddress {
        name
        phone
        address1
        city
        stateId {
          id
          name
        }
        zipCode
        customerId
      }
      deliveryAddress {
        name
        phone
        address1
        city
         stateId {
          id
          name
        }
        zipCode
        customerId
      }
      pickupDate
      stateId
      trackStatus
      order_amount
      package_weight
      isDeleted
        token
      isActive
      status
      courier_name
      manifest
      shipment_id
      order_id
      courier_id
      label
      paymentDetails
      createdBy
      awb_number
      updatedBy
      createdAt
      updatedAt
    }
  }
}
`
export const GET_MONTHLY_ORDERS = gql`
query DailyOrders {
  getOrderCounts {
    dailyOrders {
      count
      _id
    }
    monthlyOrders {
      _id
      count
    }
  }
}
`
export const GET_User_DAILY_MONTHLY = gql`
query GetUserCounts {
  getUserCounts {
    monthlyUser {
      _id
      count
    }
    dailyUser {
      _id
      count
    }
  }
}
`
export const GET_TOTAL_COUNT_DASHBOARD = gql`
query GetDashboardCount {
  getDashboardCount {
    payment
    order
    customer
    ticket
  }
}
`

export const GET_TOTAL_COUNT_TICKET = gql
  `query GetSupportCounts {
  getSupportCounts {
    monthlySupport {
      _id
      count
    }
    dailySupport {
      _id
      count
    }
  }
}`

export const GET_ORDERS_BY_ID = gql`
query GetOrder($getOrderId: String) {
  getOrder(id: $getOrderId) {
    customerId
    order_number
    ProductPrice
    package_weight_Length
    ProductName
    pickupAddress {
      name
      phone
      address1
      city
      stateId {
        id
        name
      }
      zipCode
      customerId
    }
    deliveryAddress {
      name
      phone
      address1
      city
        stateId {
          id
          name
        }
      zipCode
      customerId
    }
    pickupDate
    stateId
    trackStatus
      token
    order_amount
    package_weight
    isDeleted
    isActive
    status
    courier_name
    manifest
    shipment_id
    order_id
    courier_id
    label
    createdBy
    awb_number
    updatedBy
    createdAt
    updatedAt
    paymentDetails
  }
}
  `

export const GETTURNOVER = gql`
  query OrderAmountTotals {
  orderAmountTotals {
    dailyTotal
    monthlyTotal
    yearlyTotal  
  }
}
  `
export const GET_NETAMOUNT = gql`
  query OrderNetAmountTotals {
  orderNetAmountTotals {
    dailyTotal
    monthlyTotal
    yearlyTotal
  }
}
  `

export const GETNETAMOUNT = gql`
 query GetOrderWithNetAmount($page: Int, $sort: orderSort, $limit: Int, $filter: String, $from: String, $to: String) {
  GetOrderWithNetAmount(page: $page, sort: $sort, limit: $limit, filter: $filter, from: $from, to: $to) {
    orders {
      createdAt
      netAmount
      order_number
      thirdPartyAmount
      order_amount
      cashFreeAmount
      courierAmount
      order_percentage
      status
      paymentDetails
      awb_number
    }
    count
    totalNetAmount
  ThirdPartyNetAmount
  ThirdCashFreeNetAmount
  TotalTurnOver
  }
}
  `