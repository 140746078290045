import React, { useEffect, useState } from 'react'
import Header from '../Header/Header.js'
import Footer from '../Footer/Footer.js'
import { MdErrorOutline } from "react-icons/md";
import { SlLocationPin, SlEnvolope } from "react-icons/sl";
import { GoCircle } from "react-icons/go";
import AddressModal from './AddressModel.js';
import AddressDetailsModel from './AddressDetailsModel.js';
import { useLocation } from 'react-router-dom';
import PaymentTotalModel from './PaymentTotalModel.js';
import { FaCaretRight } from "react-icons/fa";
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_ORDER } from '../graphql/Mutation/Ordre.js';
import DeliveryModal from './DeliveryModal.js';
import DeliveryDetailsModel from './DeliveryDetailsModel.js';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { GET_DELIVERY_ADDRESS } from '../graphql/Query/GetDeliveryOfCustomer.js';
import { GET_RECEIVER_DATA } from '../graphql/Query/GetReceiverOfCustomer.js';
import './documentShipping.css'
import SpinLoader from '../components/SpinLoader.js';
import { toast } from 'react-toastify';
import { FaRegEdit } from "react-icons/fa";
import s1 from '../asset/img/sd1.png';
import s2 from '../asset/img/sd2.png';
import sd3 from '../asset/img/sd3.png';
import sd4 from '../asset/img/sd4.png';
import sd5 from '../asset/img/sd5.png';
import sd6 from '../asset/img/sd6.png';
import sd7 from '../asset/img/sd7.png';
import docs from '../asset/img/gg1.jpg';
import domestic from '../asset/img/boxs.jpg';
import { BsBox } from "react-icons/bs";
import cbox from '../asset/img/1-3_t802.png';
import dbox from '../asset/img/dbox.jpg';
import useScrollToTop from '../components/useScrollToTop.js';



const options = [
    { value: 'Books & Documents', label: 'Books & Documents' },
    { value: 'Clothes & Personal Items', label: 'Clothes & Personal Items' },
    { value: 'Consumables', label: 'Consumables' },
    { value: 'Electronics', label: 'Electronics' },
    { value: 'Household Items', label: 'Household Items' },
    { value: 'Sports Equipment', label: 'Sports Equipment' },
    { value: 'Furniture', label: 'Furniture' },
    { value: 'Jewelry & Accessories', label: 'Jewelry & Accessories' },
    { value: 'Beauty & Personal Care', label: 'Beauty & Personal Care' },
    { value: 'Toys & Games', label: 'Toys & Games' },
    { value: 'Office Supplies', label: 'Office Supplies' },
    { value: 'Groceries', label: 'Groceries' },
    { value: 'Health & Beauty', label: 'Health & Beauty' },
    { value: 'Automotive', label: 'Automotive' },
    { value: 'Musical Instruments', label: 'Musical Instruments' },
    { value: 'Gardening Tools', label: 'Gardening Tools' },
    { value: 'Kitchen Appliances', label: 'Kitchen Appliances' },
    { value: 'Pet Supplies', label: 'Pet Supplies' },
    { value: 'Art & Craft', label: 'Art & Craft' },
    { value: 'Baby Products', label: 'Baby Products' },
    { value: 'Tools & Hardware', label: 'Tools & Hardware' },
    { value: 'Travel Accessories', label: 'Travel Accessories' },
    { value: 'Industrial Supplies', label: 'Industrial Supplies' },
    { value: 'Bags & Luggage', label: 'Bags & Luggage' },
    { value: 'Footwear', label: 'Footwear' },
    { value: 'Home Decor', label: 'Home Decor' },
    { value: 'Other', label: 'Other' },

];

const weightOptions = [
    { value: "0.5 Kg", label: "0.5 KG" },
    { value: "1 Kg", label: "1 Kg" },
    { value: "2 Kg", label: "2 Kg" },
    { value: "3 Kg", label: "3 Kg" },
    { value: "4 Kg", label: "4 Kg" },
    { value: "5 Kg", label: "5 Kg" },
    { value: "6 Kg", label: "6 Kg" },
    { value: "7 Kg", label: "7 Kg" },
    { value: "8 Kg", label: "8 Kg" },
    { value: "9 Kg", label: "9 Kg" },
    { value: "10 Kg", label: "10 Kg" },
    { value: "11 Kg", label: "11 Kg" },
    { value: "12 Kg", label: "12 Kg" },
    { value: "13 Kg", label: "13 Kg" },
    { value: "14 Kg", label: "14 Kg" },
    { value: "15 Kg", label: "15 Kg" },
    { value: "16 Kg", label: "16 Kg" },
    { value: "17 Kg", label: "17 Kg" },
    { value: "18 Kg", label: "18 Kg" },
    { value: "19 Kg", label: "19 Kg" },
    { value: "20 Kg", label: "20 Kg" },
    { value: "21 Kg", label: "21 Kg" },
    { value: "22 Kg", label: "22 Kg" },
    { value: "23 Kg", label: "23 Kg" },
    { value: "24 Kg", label: "24 Kg" },
    { value: "25 Kg", label: "25 Kg" },
    { value: "26 Kg", label: "26 Kg" },
    { value: "27 Kg", label: "27 Kg" },
    { value: "28 Kg", label: "28 Kg" },
    { value: "29 Kg", label: "29 Kg" },
    { value: "30 Kg", label: "30 Kg" },
];
const DocumentShipping = () => {

    useScrollToTop();


    const location = useLocation();
    const imageurl = location?.state?.key
    const [colorError, setColorError] = useState(undefined);
    const [showModal, setShowModal] = useState(false);
    const [showModalAddress, setShowModalAddress] = useState(false);

    const [deliveryshowModal, setdeliveryShowModal] = useState(false);
    const [deliveryModal, setdeliveryModal] = useState(false);

    const [paymentShowModel, setPaymentShowModel] = useState(false);
    const [setHandlePayModel] = useState(false);
    const [WeightType, setWeightType] = useState('');
    const [weightInput, setWeightInput] = useState('');
    const [selectedPickupAddress, setSelectedPickupAddress] = useState('');
    const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState('');
    const [paymentSessionId, setSessionId] = useState();
    const [data, setData] = useState([]);
    const [ProductName, setProductName] = useState('');
    const [ProductPrice, setProductPrice] = useState('');
    const [courier_id, setcourier_id] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const id = localStorage.getItem('id');
    const handleButtonClick = (weightType) => {
        const weightValue = parseFloat(weightType);

        switch (true) {
            case weightValue === 0.5:
                setcourier_id('1');
                setWeightInput(500);
                break;
            case weightValue === 1:
                setcourier_id('12298');
                setWeightInput(1000);
                break;
            case weightValue >= 2 && weightValue <= 4:
                setcourier_id('2');
                setWeightInput(weightValue * 1000);
                break;
            case weightValue >= 5 && weightValue <= 9:
                setcourier_id('3');
                setWeightInput(weightValue * 1000);
                break;
            case weightValue >= 10 && weightValue <= 30:
                setcourier_id('4');
                setWeightInput(weightValue * 1000);
                break;
            default:
                break;
        }
        setWeightType(weightType);
    };
    const { data: getDeliveryData, refetch } = useQuery(GET_DELIVERY_ADDRESS, {
        variables: {
            "customerId": id,
            "page": 1,
            "limit": 10,
            "sort": {
                "key": "createdAt",
                "type": -1
            },
        },
        fetchPolicy: 'cache-and-network',
    });
    const { data: getReceiverData, refetch: reciverRefetch } = useQuery(GET_RECEIVER_DATA, {
        variables: {
            "customerId": id,
            "page": 1,
            "limit": 10,
            "sort": {
                "key": "createdAt",
                "type": -1
            },
        },
        fetchPolicy: 'cache-and-network',
    });
    const [pickupAddress, setPickupAddress] = useState({
        warehouse_name: '',
        name: '',
        address1: '',
        city: '',
        stateId: {
            id: '',
            name: '',
        },
        zipCode: '',
        phone: ''
    });
    const [deliveryAddress, setDeliveryAddress] = useState({
        name: '',
        address1: '',
        city: '',
        stateId: {
            id: '',
            name: '',
        },
        zipCode: '',
        phone: ''
    });
    const [addOrder] = useMutation(CREATE_ORDER);

    const handleCloseAddressDetails = () => {
        setShowModalAddress(false);
        setShowModal(true);
    };
    const handleCloseDeliveryDetails = () => {
        setdeliveryModal(false);
        setdeliveryShowModal(true)
    };

    const capitalizeFirstLetter = (text) => {
        if (!text) return text;
        return text.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
    };
    const createOrderData = async () => {
        const errors = [];
        if (!selectedPickupAddress) errors.push("Pickup Address");
        if (!selectedDeliveryAddress) errors.push("Delivery Address");
        if (!WeightType) errors.push("Weight Type");
        if (!weightInput) errors.push("Weight Input");
        if (!ProductName) errors.push("Product Name");
        if (!ProductPrice) errors.push("Product Price");

        if (errors.length > 0) {
            const errorMessage = ` ${errors.join(', ')}. required fields`;
            toast.error(errorMessage);
            return;
        }
        setIsLoading(true);
        try {
            const pickupAddress = {
                warehouse_name: selectedPickupAddress?.warehouse_name,
                name: selectedPickupAddress?.name,
                address1: selectedPickupAddress?.address1,
                city: selectedPickupAddress?.city,
                stateId: {
                    name: selectedPickupAddress?.stateId,
                },
                zipCode: selectedPickupAddress?.zipCode,
                phone: selectedPickupAddress?.phone,
            }
            const deliveryAddress = {
                name: selectedDeliveryAddress?.name,
                address1: selectedDeliveryAddress?.address1,
                city: selectedDeliveryAddress?.city,
                stateId: {
                    name: selectedDeliveryAddress?.stateId,
                },
                zipCode: selectedDeliveryAddress?.zipCode,
                phone: selectedDeliveryAddress?.phone,
            }
            const { data } = await addOrder({
                variables: {
                    input: {
                        pickupAddress,
                        deliveryAddress,
                        package_weight: parseFloat(weightInput),
                        package_weight_Length: WeightType,
                        courier_id: courier_id,
                        ProductName: ProductName,
                        ProductPrice: ProductPrice,

                    }
                }
            });
            if (data?.addOrder?.paymentSessionId) {
                setIsLoading(false);
                setSessionId(data?.addOrder?.paymentSessionId);
                setPaymentShowModel(true);
                setData(data?.addOrder);
            }
        } catch (err) {
            toast.error(err.message)
            setIsLoading(false);
        }
    };
    const validationSchema = Yup.object({
        weight: Yup.string().required('Weight is required'),
        ProductPrice: Yup.number()
            .required('Product Value is required')
            .positive('Product Value must be a positive number')
            .typeError('Product Value must be a number'),
        productCategory: Yup.object()
            .shape({
                value: Yup.string().required('Product Category is required'),
            })
            .required('Product Category is required'),
    });
    const allBinsHandleChange = (selectedValue, setFieldValue) => {
        setProductName(selectedValue?.label);
        setFieldValue('productCategory', selectedValue);
        setColorError(true);
    };

    return (
        <div>
            <Header />
            <div className="relative isolate overflow-hidden bg-gray-900 py-16 sm:py-14">
                <img
                    src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
                    alt=""
                    className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
                />
                <div
                    className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div
                    className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div className="mx-auto max-w-auto md:p-6 lg:px-8">
                    <div className="flex flex-col lg:flex-row gap-y-10 justify-between">
                        <Formik
                            initialValues={{ pickupAddress: '', deliveryAddress: '', weight: '', ProductPrice: '', productCategory: { value: '' } }}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                            }}
                        >
                            {({ setFieldValue, values }) => (
                                <Form className="w-full lg:w-2/3 bg-white md:p-8 p-6 md:rounded-l-lg shadow-md">
                                    <div className='space-y-8'>
                                        <div className='flex justify-between'>
                                            <div className='flex'>


                                                {imageurl ? <BsBox className='text-3xl me-3 text-gray-800 drop-shadow-lg' /> : <SlEnvolope className='text-3xl me-3 text-gray-800 drop-shadow-lg' />}
                                                {imageurl ? <h1 className="text-xl font-bold">Domestic parcel in india</h1> : <h1 className="text-xl font-bold">Document ex.Letter, Paper etc. Ship now</h1>}
                                            </div>
                                            <h1 className="font-bold hidden md:block text-slate-600">1 / 1</h1>
                                        </div>
                                        <div className='flex space-x-4'>
                                            <div className='space-y-1 text-gray-400'>
                                                <GoCircle />
                                                <ul className='ms-1.5'>
                                                    <li className='text-xs leading-3'>I</li>
                                                    <li className='text-xs leading-3'>I</li>
                                                    <li className='text-xs leading-3'>I</li>
                                                    <li className='text-xs leading-3'>I</li>
                                                    <li className='text-xs leading-3'>I</li>
                                                    <li className='text-xs leading-3'>I</li>
                                                    <li className='text-xs leading-3'>I</li>
                                                </ul>
                                                <SlLocationPin />
                                            </div>
                                            <div className='space-y-7 flex-initial w-full text-ellipsis overflow-hidden'>
                                                <div className="mb-4">
                                                    <label className="block text-gray-700 text-sm font-semibold mb-3" htmlFor="pickupAddress">
                                                        Pickup Address <span className="text-red-500">*</span>
                                                    </label>
                                                    {!selectedPickupAddress && <div className="relative">
                                                        <Field
                                                            name="pickupAddress"
                                                            className="flex w-full cursor-pointer items-center border-slate-500 border rounded-lg py-2 px-3 focus:outline-none focus:ring-slate-800 focus:border-slate-800"
                                                            placeholder="pickup address"
                                                            onClick={() => setShowModal(true)}
                                                            readOnly
                                                        />
                                                        <FaCaretRight className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-800" />
                                                    </div>}
                                                    <ErrorMessage name="pickupAddress" component="div" className="text-red-500 text-sm mt-2" />
                                                    {selectedPickupAddress && (
                                                        <ul className="space-y-6 md:space-y-5 mt-3 border border-slate-300 p-4 rounded-xl">
                                                            <li className="flex justify-between cursor-pointer items-center text-ellipsis overflow-hidden" onClick={() => setShowModal(true)}>
                                                                <div className="flex items-center">
                                                                    <div>
                                                                        <div className="flex space-x-2 mb-1.5">
                                                                            <p className="font-semibold md:text-black text-gray-800 text-sm">{capitalizeFirstLetter(selectedPickupAddress?.name)}</p>
                                                                            <p className="text-sm self-center text-gray-500">l +91 {(selectedPickupAddress?.phone)}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="text-slate-500 font-normal text-sm">{capitalizeFirstLetter(selectedPickupAddress?.address1)}.</p>
                                                                            <p className="text-slate-500 font-normal text-sm">{capitalizeFirstLetter(selectedPickupAddress?.city)}. {capitalizeFirstLetter(selectedPickupAddress?.stateId)} - {capitalizeFirstLetter(selectedPickupAddress?.zipCode)}</p>
                                                                        </div>
                                                                        <div className='font-semibold mt-4 flex space-x-1 items-center text-green-600' onClick={() => setShowModal(true)}>
                                                                            <FaRegEdit className='text-lg text-green-600' />
                                                                            <p className='text-xs text-green-600'>ADDRESS UPDATE</p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    )}
                                                </div>


                                                <div className="mb-4">
                                                    <label className="block text-gray-700 text-sm font-semibold mb-3" htmlFor="deliveryAddress">
                                                        Delivery Address <span className="text-red-500">*</span>
                                                    </label>
                                                    {!selectedDeliveryAddress && <div className="relative">
                                                        <Field
                                                            name="deliveryAddress"
                                                            className="flex w-full cursor-pointer items-center border-slate-500 border rounded-lg py-2 px-3 focus:outline-none focus:ring-slate-800 focus:border-slate-800"
                                                            placeholder="delivery address"
                                                            onClick={() => setdeliveryShowModal(true)}
                                                            readOnly
                                                        />
                                                        <FaCaretRight className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-800" />
                                                    </div>}
                                                    <ErrorMessage name="deliveryAddress" component="div" className="text-red-500 text-sm mt-2" />
                                                    {selectedDeliveryAddress &&
                                                        <ul className="space-y-6 md:space-y-5 mt-3 border border-slate-300 p-4 rounded-xl ">
                                                            <li className="flex justify-between cursor-pointer items-center text-ellipsis overflow-hidden" onClick={() => setdeliveryShowModal(true)}>
                                                                <div className="flex items-center">
                                                                    <div>
                                                                        <div className='flex space-x-2 mb-1.5'>
                                                                            <p className="font-semibold md:text-black text-gray-800 text-sm">{capitalizeFirstLetter(selectedDeliveryAddress?.name)}</p>
                                                                            <p className='text-sm self-center text-gray-500'>l +91 {(selectedDeliveryAddress?.phone)}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="text-slate-500 font-normal text-sm">{capitalizeFirstLetter(selectedDeliveryAddress?.address1)}.</p>
                                                                            <p className="text-slate-500 font-normal text-sm">{capitalizeFirstLetter(selectedDeliveryAddress?.city)}.  {capitalizeFirstLetter(selectedDeliveryAddress?.stateId)} - {capitalizeFirstLetter(selectedDeliveryAddress?.zipCode)}</p>
                                                                        </div>
                                                                        <div className='font-semibold mt-4 flex space-x-1 items-center text-green-600' onClick={() => setdeliveryShowModal(true)}>
                                                                            <FaRegEdit className='text-lg text-green-600' />
                                                                            <p className='text-xs text-green-600'>ADDRESS UPDATE</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative z-50">
                                            <label>Weight (Kg) <span className="text-red-500">*</span></label>
                                            <div className="mt-2 z-50">
                                                <Field name="weight">
                                                    {({ field, form }) => {
                                                        const selectedOption = weightOptions.find(option => option.value === field.value);
                                                        return (
                                                            <Select
                                                                value={selectedOption}
                                                                options={weightOptions}
                                                                className="w-full focus:outline-none z-50"
                                                                styles={{
                                                                    control: (base, state) => ({
                                                                        ...base,
                                                                        borderColor: state.isFocused ? '#64748B' : '#64748B',
                                                                        '&:hover': { borderColor: '#64748B' },
                                                                        boxShadow: state.isFocused ? '0 0 0 0px #000000' : 'none',
                                                                        borderRadius: '0.375rem',
                                                                    }),
                                                                    singleValue: (base) => ({
                                                                        ...base,
                                                                        color: '#000000',
                                                                    }),
                                                                    option: (base, state) => ({
                                                                        ...base,
                                                                        backgroundColor: state.isSelected ? '#000000' : state.isFocused ? '#f0f0f0' : 'white',
                                                                        color: state.isSelected ? 'white' : '#000000',
                                                                        '&:hover': {
                                                                            backgroundColor: '#f0f0f0',
                                                                            color: '#000000',
                                                                        }
                                                                    }),
                                                                    placeholder: (base) => ({
                                                                        ...base,
                                                                        fontSize: '13.7px',
                                                                        color: '#A3AAB5',
                                                                    }),
                                                                }}
                                                                isSearchable={false}
                                                                placeholder="Select Weight"
                                                                onChange={option => {
                                                                    form.setFieldValue(field.name, option.value);
                                                                    handleButtonClick(option.value);
                                                                }}
                                                                onBlur={() => form.setFieldTouched(field.name, true)}
                                                            />
                                                        );
                                                    }}
                                                </Field>
                                                <ErrorMessage name="weight" component="div" className="text-red-600 text-sm mt-1 z-50" />
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="ItemSelect" className="block text-sm font-medium text-gray-700 mb-3">
                                                Courier Category <span className="text-red-500">*</span>
                                            </label>
                                            <Select
                                                className="basic-single ffPoppins"
                                                options={options}
                                                onChange={(selectedValue) => allBinsHandleChange(selectedValue, setFieldValue)}
                                                styles={{
                                                    control: (base, state) => ({
                                                        ...base,
                                                        borderColor: state.isFocused ? '#64748B' : '#64748B',
                                                        '&:hover': { borderColor: '#64748B' },
                                                        boxShadow: state.isFocused ? '0 0 0 0px #000000' : 'none',
                                                        borderRadius: '0.375rem',
                                                    }),
                                                    singleValue: (base) => ({
                                                        ...base,
                                                        color: '#000000',
                                                    }),
                                                    option: (base, state) => ({
                                                        ...base,
                                                        backgroundColor: state.isSelected ? '#000000' : state.isFocused ? '#f0f0f0' : 'white',
                                                        color: state.isSelected ? 'white' : '#000000',
                                                        '&:hover': {
                                                            backgroundColor: '#f0f0f0',
                                                            color: '#000000',
                                                        },
                                                    }),
                                                    placeholder: (base) => ({
                                                        ...base,
                                                        fontSize: '13.7px',
                                                        color: '#A3AAB5',
                                                    }),
                                                }}
                                                isSearchable={false}
                                                placeholder="Select Package Category"
                                            />
                                            <ErrorMessage name="productCategory.value" component="div" className="text-red-500 text-sm mt-2" />
                                        </div>
                                        <div>
                                            <label htmlFor="ProductPrice" className="block text-sm font-medium text-gray-700 mb-3">
                                                Courier Value Rs.<span className="text-red-500">*</span>
                                            </label>
                                            <div className="mt-1 flex rounded-md shadow-sm">
                                                <Field
                                                    type="number"
                                                    id="ProductPrice"
                                                    name="ProductPrice"
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (value.length <= 4) {
                                                            setFieldValue('ProductPrice', value);
                                                            setProductPrice(value);
                                                        }
                                                    }}
                                                    onWheel={(e) => e.target.blur()}
                                                    className="flex-1 block w-full px-3 py-2 border border-slate-500 rounded-md focus:outline-none focus:ring-black focus:border-black sm:text-sm"
                                                    placeholder="Enter Value Ex.Rs.199"
                                                />
                                            </div>
                                            <ErrorMessage name="ProductPrice" component="div" className="text-red-500 text-sm mt-2" />
                                        </div>
                                        <div className='mt-6'>
                                            <div className='flex space-x-2'>
                                                <MdErrorOutline className='text-xl' />
                                                <p className='text-sm font-semibold'>Book before 2pm remaining for the Same-Day Pickup</p>
                                            </div>
                                            <p className='text-xs mt-1'>Your Courier Delivery: Minimum 1 - Maximum 6 Days (Depending on the location)</p>
                                        </div>
                                        <button
                                            className={`w-full mt-7 bg-gray-800 text-white p-2 rounded-lg hover:bg-gray-900 transition duration-300 ${isLoading ? 'cursor-not-allowed' : ''}`}
                                            type="submit"
                                            onClick={createOrderData}
                                            disabled={isLoading}
                                        >
                                            <div className='flex justify-center'>
                                                <span className='me-2'>Next</span>
                                                {isLoading && <SpinLoader />}
                                            </div>
                                        </button>

                                        <div>
                                            <h1 className="text-lg font-semibold text-gray-800 mb-6">
                                                Incorrect Weight Entry for Courier Shipments: Valid and Invalid Scenarios
                                            </h1>
                                            <p className="text-gray-700 mb-6">
                                                When shipping parcels, it’s crucial to enter the correct weight for your packages. Entering the wrong weight can cause delays, returns, or additional charges. Here’s how different scenarios are handled:
                                            </p>

                                            <div className="mb-4">
                                                <h2 className=" font-semibold text-gray-800 mb-2">
                                                    1. Small Weight Discrepancies (400 to 700 gm): Valid
                                                </h2>
                                                <p className="text-gray-700">
                                                    Minor weight differences ranging from 400 to 700 grams are generally considered acceptable. In such cases, your shipment will still be processed without being returned, as long as it doesn’t significantly impact the shipping cost or logistics.
                                                </p>
                                            </div>

                                            <div className="mb-4">
                                                <h2 className=" font-semibold text-gray-800 mb-2">
                                                    2. Large Weight Discrepancies: Invalid and Return
                                                </h2>
                                                <p className="text-gray-700">
                                                    If a substantial weight difference is entered—such as declaring a much lighter weight for a significantly heavier package—the shipment will be marked as invalid. In such cases, your courier will likely be returned to the sender, and you may be charged additional fees for the discrepancy.
                                                </p>
                                            </div>
                                            <div>
                                                <h2 className=" font-semibold text-gray-800 mb-2">
                                                    3. Your Courier Delivery: Minimum 1 - Maximum 6 Days
                                                </h2>
                                                <p className="text-gray-700">
                                                    At UK Logistics, we ensure efficient and timely delivery of your parcels. Depending on the location and service selected, your courier will be delivered within a minimum of 1 day and a maximum of 6 days. Our goal is to provide fast and reliable service, keeping your needs in mind while ensuring that your shipments arrive safely within the given timeframe.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <div className="w-full lg:w-1/3 bg-white p-6 md:rounded-r-lg shadow-md mt-6 lg:mt-0 lg:ml-3">
                            {imageurl ? <video
                                // controls
                                autoPlay
                                loop
                                muted
                                className="rounded-xl mb-3"
                            >
                                <source src="https://vaghasiyakishan111.s3.ap-south-1.amazonaws.com/longVideo/Untitled+1_1080p.mp4" type="video/mp4" className='cursor-pointer rounded-xl' />
                                Your browser does not support the video tag.
                            </video>
                                :
                                <video
                                    // controls
                                    autoPlay
                                    loop
                                    muted
                                    className="rounded-xl mb-3"
                                >
                                    <source src="https://vaghasiyakishan111.s3.ap-south-1.amazonaws.com/longVideo/Untitled+2_1080p_2.mp4" type="video/mp4" className='cursor-pointer rounded-xl' />
                                    Your browser does not support the video tag.
                                </video>
                            }
                            {imageurl ? <img src={domestic} alt="" className='rounded-lg hover:opacity-80' /> : <img src={docs} alt="" className='rounded-lg hover:opacity-80' />}
                            {/* {imageurl ? <img src={cbox} alt="" className='rounded-lg hover:opacity-80 mt-3' /> : <img src={dbox} alt="" className='rounded-lg hover:opacity-80 mt-3 h-40' />} */}
                            <h2 className="text-xl font-semibold my-6">Things to keep in mind</h2>
                            <div className="mb-4">
                                <h3 className="font-semibold text-slate-800 text-sm">Package Weight</h3>
                                <p className="text-gray-500 text-sm">We only deliver packages up to 30kgs.</p>
                            </div>
                            <div className="mb-4">
                                <h3 className="font-semibold text-slate-800 text-sm">Packaging</h3>
                                <p className="text-gray-500 text-sm">We do not provide packaging service. Please keep the items packed for our delivery executive to pickup.</p>
                            </div>
                            <div className="mb-4">
                                <h3 className="font-semibold text-slate-800 text-sm">Restricted/Illegal item</h3>
                                <p className="text-gray-500 text-sm">Please ensure you are not sending any restricted/illegal item</p>
                            </div>
                            <div className="mb-4">
                                <h3 className="font-semibold text-slate-800 text-sm">Multiple packages</h3>
                                <p className="text-gray-500 text-sm">We only allow one box/package/parcel per order</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mx-auto max-w-auto md:p-6 lg:px-8 my-7 px-6">
                <div className='grid md:grid-cols-2 grid-cols-1'>
                    <div>
                        <p className='font-bold text-3xl'>Shipment labelling</p>

                        <p className='text-sm text-slate-600 mt-7'>Proper labeling is crucial for faster and safe handling of the shipment in reaching the intended destination.</p>
                        <p className='font-bold mt-4 mb-3 text-lg'>Essential data</p>
                        <p className='text-sm text-slate-600 mb-1'>● Barcode height (min. size : 8 mm)</p>
                        <p className='text-sm text-slate-600 mb-1'>● Waybill number and Barcode (min. size : 8 MIL, 1MIL= 0.2032mm)</p>
                        <p className='text-sm text-slate-600 mb-1'>● Order number and Barcode (min. size : 8 MIL, 1MIL= 0.2032mm)</p>
                        <p className='text-sm text-slate-600 mb-1'>● Consignee address (min. font size: 8pts, Calibri body)</p>
                        <p className='text-sm text-slate-600 mb-1'>● Return address (min. font size: 8pts, Calibri body)</p>
                        <p className='text-sm text-slate-600 mb-1'>● Retail/Tax Invoice</p>

                        <p className='font-bold mt-7 mb-3 text-lg'>Location of Label</p>

                        <p className='text-sm text-slate-600 mb-1.5'>● Paste the label on a seamless flat face of the box/flyer</p>
                        <p className='text-sm text-slate-600 mb-1.5'>● Paste the label away from seams or edges of the box</p>
                        <p className='text-sm text-slate-600 mb-1.5'>● Readability of barcode is important for faster and correct processing of shipments</p>
                        <p className='text-sm text-slate-600 mb-1.5'>● In flyers/poybags, all the essential data on labels should be visible and the label should be placed flat</p>
                        <p className='text-sm text-slate-600 mb-1.5'>● The pocket containing the label in flyer should be taped at the opening to avoid the loss of label
                        </p>
                        <p className='text-sm text-slate-600 mb-1.5'>● Thermal printed labels are strongly recommended over regular labels</p>

                    </div>

                    <div className='space-y-6 mx-auto'>
                        <img src={s1} className='h-72 w-auto mx-auto' alt="" />
                        <img src={s2} className='h-56 w-auto mx-auto' alt="" />
                    </div>
                </div>

                <p className='font-bold mt-8 mb-12 text-lg'>What not to do</p>

                <div className='grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 space-y-3 mb-8'>
                    <img src={sd3} className='h-56 w-auto mx-auto' alt="" />
                    <img src={sd4} className='h-56 w-auto mx-auto' alt="" />
                    <img src={sd5} className='h-56 w-auto mx-auto' alt="" />
                    <img src={sd6} className='h-56 w-auto mx-auto' alt="" />
                    <img src={sd7} className='h-56 w-auto mx-auto' alt="" />
                </div>

            </div>
            <AddressModal data={getReceiverData} refetch={reciverRefetch} show={showModal} onClose={() => setShowModal(false)} setShowModal={setShowModal} setShowModalAddress={setShowModalAddress} setSelectedPickupAddress={setSelectedPickupAddress} />
            <AddressDetailsModel refetch={reciverRefetch} show={showModalAddress} onClose={handleCloseAddressDetails} setShowModalAddress={setShowModalAddress} setShowModal={setShowModal} setPickupAddress={setPickupAddress} pickupAddress={pickupAddress} setSelectedPickupAddress={setSelectedPickupAddress} />
            <DeliveryDetailsModel refetch={refetch} show={deliveryModal} onClose={handleCloseDeliveryDetails} setdeliveryModal={setdeliveryModal} setdeliveryShowModal={setdeliveryShowModal} setDeliveryAddress={setDeliveryAddress} deliveryAddress={deliveryAddress} setSelectedDeliveryAddress={setSelectedDeliveryAddress} />
            <DeliveryModal refetch={refetch} show={deliveryshowModal} data={getDeliveryData} onClose={() => setdeliveryShowModal(false)} setdeliveryShowModal={setdeliveryShowModal} setdeliveryModal={setdeliveryModal} setSelectedDeliveryAddress={setSelectedDeliveryAddress} />
            <PaymentTotalModel data={data} paymentSessionId={paymentSessionId} show={paymentShowModel} onClose={() => setPaymentShowModel(false)} setPaymentShowModel={setPaymentShowModel} setHandlePayModel={setHandlePayModel} />
            <Footer />
        </div>
    )
}

export default DocumentShipping
