import { gql } from "@apollo/client";

export const SIGN_UP = gql`
mutation SignUp($email: String) {
  signUp(email: $email) {
    token
    user {
      id
      firebaseUserId
      token
      email
      password
      email
      userType
      isActive
      verified
      ApprovePrivacyPolicy
      createdAt
      updatedAt
    }
    success
    message
  }
}
`;

export const VERIFY_OTP = gql`
mutation VerifyOtp($email: String, $otp: Number, $ApprovePrivacyPolicy: Boolean) {
  verifyOtp(email: $email, otp: $otp, ApprovePrivacyPolicy: $ApprovePrivacyPolicy) {
    token
    user {
      id
      firebaseUserId
      token
      email
      password
      email
      userType
      isActive
      verified
      ApprovePrivacyPolicy
      createdAt
      updatedAt
    }
    success
    message
  }
}
`
export const SIGN_UP_WITH_GOOGLE_OR_FACEBOOK = gql`
mutation SignUpWithGoogleOrFacebook($input: signUpWithGoogleOrFacebookInput) {
  signUpWithGoogleOrFacebook(input: $input) {
    _id
    token
    name
    email
    password
    verified
    userType
    isDeleted
    isActive
    createdAt
    createdBy
    modifiedBy
  }
}
`

export const UPDATE_MOBILE = gql`
mutation MobileNumberUpdateUser($mobileNumberUpdateUserId: ID!, $mobile: String!) {
  mobileNumberUpdateUser(id: $mobileNumberUpdateUserId, mobile: $mobile) {
    success
    message
    user {
      id
      firebaseUserId
      token
      email
      password
      mobile
      userType
      isActive
      verified
      ApprovePrivacyPolicy
      createdAt
      updatedAt
    }
  }
}
`