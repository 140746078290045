import React, { useEffect } from 'react';
import { useMutation } from "@apollo/client";
import { useNavigate } from 'react-router-dom';
import { auth, facebookProvider, googleProvider, signInWithPopup } from './firebase.js';
import { SIGN_UP_WITH_GOOGLE_OR_FACEBOOK } from '../graphql/Mutation/Signin.js';
import { toast } from 'react-toastify';
import googlelogo from '../asset/img/googlelogo.png';

const GoogleFacebookButton = ({ isCheckboxChecked, setCheckboxError, refetch }) => {
    const navigate = useNavigate();
    const [signUpWithGoogleOrFacebook, { loading: signUpWithGoogleLoading, data }] = useMutation(SIGN_UP_WITH_GOOGLE_OR_FACEBOOK);

    useEffect(() => {
        if (data?.signUpWithGoogleOrFacebook) {
            const userData = data?.signUpWithGoogleOrFacebook;
            const { token, userType, _id } = userData;
            localStorage.setItem("token", token)
            localStorage.setItem("id", _id)
            localStorage.setItem("role", userType);
            refetch();
            toast.success('User SignUp SuccessFully!');
            navigate("/")

        }
    }, [data])

    const handleSignIn = async (provider) => {
        if (!isCheckboxChecked) {
            setCheckboxError(true);
            return;
        }
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result?.user;
            const signUpInput = {
                name: user?.displayName,
                email: user?.email,
                photoURL: user?.photoURL,
                firebaseUserId: user?.uid,
                verified: user?.emailVerified,
                ApprovePrivacyPolicy: isCheckboxChecked
            };
            await signUpWithGoogleOrFacebook({ variables: { input: signUpInput } });
        } catch (error) {
        }
    };

    return (
        <>
            <div className='flex flex-col space-y-4'>
                <button type="button" onClick={() => handleSignIn(googleProvider)} className='border bg-gray-50 justify-center items-center flex border-gray-300 py-2 rounded-full font-semibold'>
                    <img src={googlelogo} className='h-7 w-7' alt="" />
                    <p className='ml-3'>Continue with Google</p>
                </button>
            </div>
        </>
    );
    // );
};

export default GoogleFacebookButton;
