import React from 'react'
import Header from '../Header/Header.js'
import Footer from '../Footer/Footer.js'
import Typefour from '../Home/Typefour.js'
import Accordion from '../Home/Accodion.js'

const PackGuidelines = () => {
    return (
        <div>
            <Header />
            <div className='mt-8'>
                <Typefour />
                <Accordion />
            </div>
            <div className='mt-14'>
                <Footer />

            </div>
        </div>
    )
}

export default PackGuidelines
