import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
} from "firebase/auth";

const firebaseConfig = {
  // apiKey: "AIzaSyDeJezGGDUP5XrilE24Vyvn5SFvZnrndDY",
  // authDomain: "logistics-111.firebaseapp.com",
  // projectId: "logistics-111",
  // storageBucket: "drift-8e0a9.appspot.com",
  // messagingSenderId: "766291174354",
  // appId: "1:766291174354:web:f8ec88ed1562038f94de0b",


  apiKey: "AIzaSyBkSVORjlz0vnJr3615owABaUkVv0RIv1o",
  authDomain: "logistics-111.firebaseapp.com",
  // databaseURL: "https://logistics-111-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "logistics-111",
  storageBucket: "logistics-111.appspot.com",
  messagingSenderId: "877042331189",
  appId: "1:877042331189:web:8472f926ee2b5890160b7c",
  // measurementId: "G-6746WFX4ST"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const createUserOnFirebase = async ({ email, password }) => {
  return await createUserWithEmailAndPassword(auth, email, password);
};

// Export everything, including signInWithPopup
export {
  auth,
  googleProvider,
  facebookProvider,
  signInWithPopup,
  createUserOnFirebase,
};
