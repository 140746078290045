import React from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import "./pagination.css"

const CustomPagination = ({ totalItems, limit, pageNo, handlePageClick }) => {
  const totalPages = Math.ceil(totalItems / limit);

  // Helper function to generate visible page numbers
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 3; // Number of visible page numbers
    const leftOffset = Math.max(0, pageNo - Math.floor(maxVisiblePages / 2));
    const rightOffset = Math.min(totalPages - 1, pageNo + Math.floor(maxVisiblePages / 2));

    if (leftOffset > 0) {
      pageNumbers.push(
        <button
          key={0}
          onClick={() => handlePageClick(0)}
          className={`pagination-btn ${pageNo === 0 ? 'active' : ''}`}
        >
          1
        </button>
      );

      if (leftOffset > 1) {
        pageNumbers.push(<span key="ellipsis-start" className="pagination-ellipsis">...</span>);
      }
    }

    for (let i = leftOffset; i <= rightOffset; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={`pagination-btn ${pageNo === i ? 'active' : ''}`}
        >
          {i + 1}
        </button>
      );
    }

    if (rightOffset < totalPages - 1) {
      if (rightOffset < totalPages - 2) {
        pageNumbers.push(<span key="ellipsis-end" className="pagination-ellipsis">...</span>);
      }

      pageNumbers.push(
        <button
          key={totalPages - 1}
          onClick={() => handlePageClick(totalPages - 1)}
          className={`pagination-btn ${pageNo === totalPages - 1 ? 'active' : ''}`}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="custom-pagination">
      <button
        onClick={() => handlePageClick(Math.max(pageNo - 1, 0))}
        disabled={pageNo === 0}
        className={`pagination-arrow ${pageNo === 0 ? 'disabled' : ''}`}
      >
        <FiArrowLeft />
      </button>

      {generatePageNumbers()}

      <button
        onClick={() => handlePageClick(Math.min(pageNo + 1, totalPages - 1))}
        disabled={pageNo === totalPages - 1}
        className={`pagination-arrow ${pageNo === totalPages - 1 ? 'disabled' : ''}`}
      >
        <FiArrowRight />
      </button>
    </div>
  );
};

export default CustomPagination;
