export const handleLogin = async () => {
    try {
        const response = await fetch('https://shipment.xpressbees.com/api/users/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: 'vaghasiyakishan111@gmail.com',
                password: 'KIshan@1247',
            }),
        });

        if (response?.ok) {
            const data = await response.json();
            const authToken = data?.data;
            return authToken;
        } else {
            throw new Error('Failed to login');
        }
    } catch (error) {
        // console.error('Login error:', error);
    }
};