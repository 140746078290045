import React, { createContext, useState, useContext } from 'react';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [awbNumber, setAwbNumber] = useState(null);
    const [ticketId, setticketId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const openModal = (awbNumber, id) => {
        setAwbNumber(awbNumber);
        setticketId(id);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setAwbNumber(null);
    };

    return (
        <ModalContext.Provider value={{ isOpen, awbNumber, openModal, closeModal, ticketId, modalOpen, setModalOpen }}>
            {children}
        </ModalContext.Provider>
    );
};
