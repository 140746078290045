import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
// import { WebSocketLink } from '@apollo/client/link/ws/index.js';
// import { getMainDefinition } from '@apollo/client/utilities';
// import { setContext } from '@apollo/client/link/context';
// import { getMainDefinition } from '@apollo/client/utilities/index.js';
import { setContext } from '@apollo/client/link/context/index.js';
import { API_URL } from '../config.js';
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-token': localStorage.getItem('token') || '',
    },
  };
});
const httpLink = new HttpLink({
  uri: `${API_URL}/graphql`,
});
// const wsLink = new WebSocketLink({
//   uri: `${WS_URL}/graphql`,
//   options: {
//     reconnect: true,
//     connectionParams: {
//       authToken: localStorage.getItem('token'),
//     },
//   },
// });
// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     );
//   },
//   // wsLink,
//   httpLink
// );

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
