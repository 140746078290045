import { gql } from "@apollo/client";

export const ADD_TOPBAR=gql`
mutation AddTopBar($input: inputTopBar) {
  addTopBar(input: $input) {
    id
    topBar
    content
  }
}
`

export const UPDATE_TOPBAR=gql`
mutation UpdateTopBar($input: inputTopBar) {
  updateTopBar(input: $input) {
    id
    topBar
    content
  }
}
`
export const DELETE_TOPBAR=gql`
mutation DeleteTopBar($deleteTopBarId: ID) {
  deleteTopBar(id: $deleteTopBarId)
}
`

export const ADD_VALUATION=gql`
mutation AddValuation($topBar: String, $content: String) {
  AddValuation(topBar: $topBar, content: $content) {
    id
    topBar
    content
  }
}
`
export const GET_VALUATION = gql`
query GetTopBarValuation {
  getTopBarValuation {
    id
    topBar
    content
  }
}
`