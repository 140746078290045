import { gql } from "@apollo/client";



export const CREATE_SUPPORT = gql`

mutation Mutation($input: createInput) {
    createSupport(input: $input) {
      status
      message
      support {
        id
        name
        mobile
        awbNo
        orderID
        message
        createdAt
      }
    }
  }
  
`
