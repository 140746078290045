import React from 'react'
import Header from '../Header/Header.js'
import Footer from '../Footer/Footer.js'
import Label from './Label.js'

const ShippingLabel = () => {
    return (
        <div>
            <Header />
            <Label />
            <Footer />
        </div>
    )
}

export default ShippingLabel
