import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import reportWebVitals from './reportWebVitals.js';
import { ApolloProvider } from '@apollo/client';
import client from './context/Client.js';
import { ModalProvider } from './Contex.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <ApolloProvider client={client}>
    <ModalProvider>
      <App />
    </ModalProvider>
  </ApolloProvider>
);

reportWebVitals();
