import React, { useState } from 'react'
import AdminHeader from './AdminHeader.js'
import { GET_REFUND_PAYMENT } from '../graphql/Query/paymentRefund.js';
import { useMutation, useQuery } from '@apollo/client';
import Loader from '../components/Loader.js';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { UPDATE_PAYMENT_STATUS } from '../graphql/Mutation/paymentRefund.js';
import { toast } from 'react-toastify';
import CustomPagination from '../components/Pagination.js';
import useScrollToTop from '../components/useScrollToTop.js';
import { REFUND_ORDER_MUTATION } from '../graphql/Mutation/Ordre.js';


const AdminRefund = () => {
    useScrollToTop();

    const [searchUser, setSearchUser] = useState('');
    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [filter, setFilter] = useState('');
    const [activeButton, setActiveButton] = useState('open');
    const [page, setPage] = useState(0);
    const [limit] = useState(200);
    const [updatePaymentRefund] = useMutation(UPDATE_PAYMENT_STATUS);
    const [refundOrder] = useMutation(REFUND_ORDER_MUTATION);
    const { data: paymentRefundData, loading, refetch } = useQuery(GET_REFUND_PAYMENT, {
        variables: {
            page: page + 1,
            limit,
            sort: {
                key: "createdAt",
                type: -1
            },
            filter: filter,
            from: fromDate,
            to: toDate,
            status: activeButton === 'open' ? 'true' : 'false'
        },
        fetchPolicy: 'cache-and-network'
    });

    const handleSearch = (e) => {
        setFilter(searchUser);
        setPage(0);
    }
    const updateStatus = async (id, dataStatus) => {
        try {
            const { data } = await updatePaymentRefund({ variables: { updateRefundPaymentId: id, isActive: dataStatus } });
            if (data.updateRefundPayment) {
                refetch();
                toast.success('PaymentRefund close successfully')

            } else {

                toast.error('Failed to update PaymentRefund status');
            }
        } catch (error) {
            toast.error(error);
        }
    }


        const handleRefund = async (orderId,orderAmount) => {
            try {
            const {data} =  await refundOrder({
                    variables: {
                      orderId: orderId,
                      refundAmount: orderAmount
                    }
                  });
                //   console.log("data====",data)
                if(data?.refundOrder?.success){
                    toast.success(data?.refundOrder?.message)
                }else{
                    toast.error(data?.refundOrder?.message)
                }
                
            } catch (error) {
                toast.error(error)
                console.error('Error processing refund:', error);
            }
        };
        const fromDateChange = (e) => {
            setFromDate(e.target.value);
        }

        const toDateChange = (e) => {
            setToDate(e.target.value);
        }

        const handlePageClick = (selectedPage) => {
            setPage(selectedPage);
        };
        return (
            <>
                <AdminHeader />
                <div className="mx-auto max-w-auto px-2 sm:px-6 lg:px-8 my-10">

                    <div className='flex space-x-6 justify-between'>
                        <div className="lg:col-span-3 space-y-3">
                            <h2 className="text-lg font-semibold text-gray-900">Refund Payment</h2>
                            <div className="flex space-x-5">
                                <button
                                    className={` ${activeButton === 'open' ? 'bg-green-600 text-white px-3 py-1 rounded-lg font-semibold' : 'font-semibold text-gray-400 '}`}
                                    onClick={() => { setActiveButton('open'); setPage(0) }}
                                >
                                    Open
                                </button>
                                <button
                                    className={` ${activeButton === 'close' ? 'bg-red-600 text-white px-3 py-1 rounded-lg font-semibold' : 'font-semibold text-gray-400 '}`}
                                    onClick={() => { setActiveButton('close'); setPage(0) }}
                                >
                                    Closed
                                </button>
                            </div>
                        </div>
                        <div>
                            <p className='font-semibold'>Today Refund Payment :</p>
                        </div>
                    </div>

                    <div className='p-4 mt-6 max-w-full mx-auto bg-gray-50 ring-1 ring-gray-200 rounded-xl space-y-4'>
                        <div className='flex justify-between '>
                            <div>
                                <h2 className="text-xl font-semibold">Courier Filter</h2>
                            </div>

                        </div>

                        <div className='lg:flex space-y-7 lg:space-y-0 lg:justify-between'>
                            <div>
                                <p className="text-sm font-medium text-gray-700">Search</p>
                                <div className='md:flex md:space-x-4 space-x-0'>
                                    <div>
                                        <input type="text" onChange={(e) => setSearchUser(e.target.value)} onKeyDown={handleSearch} value={searchUser} placeholder="Search..." className="mt-1 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 mb-2 md:mb-0 w-full md:w-none  px-2 py-1 border rounded-md" />
                                    </div>
                                </div>
                            </div>
                            <div className='md:flex md:space-x-4 space-x-0 space-y-3 md:space-y-0'>
                                <div>
                                    <p className="text-sm font-medium text-gray-700">From</p>
                                    <div className='md:flex md:space-x-4 space-x-0'>
                                        <input
                                            type="date"
                                            className="mt-1 w-full md:w-none  px-2 py-1.5 md:py-1  focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 border rounded-md"
                                            value={fromDate}
                                            onChange={(e) => { fromDateChange(e) }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <p className="text-sm font-medium text-gray-700">To</p>
                                    <div className='md:flex md:space-x-4 space-x-0'>
                                        <div>
                                            <input
                                                type="date"
                                                className="mt-1 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 mb-2 md:mb-0 w-full md:w-none  px-2 py-1.5 md:py-1 border rounded-md"
                                                value={toDate}
                                                onChange={(e) => toDateChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <CustomPagination
                            // pageCount={paymentRefundData?.getPaymentRes?.count? Math.ceil(paymentRefundData.getPaymentRes.count/ limit) : 0}
                            totalItems={paymentRefundData?.getPaymentRes?.count || 0}
                            limit={limit}
                            pageNo={page}
                            handlePageClick={handlePageClick}
                        />


                    </div>

                    <div className="overflow-x-auto rounded-t-xl mt-14 border border-gray-200">

                        <p className='font-semibold m-3'>Total : {paymentRefundData?.getPaymentRes?.count}</p>
                        <table className="min-w-full bg-white">
                            <thead>
                                <tr>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">No.</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Date</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Order ID (Cashfree)</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Transaction ID</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Customer Ref ID</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">AWB NO.</td>
                                    {/* <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">UPI ID or Mobile No.</td> */}
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Cancel Resoan</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Transaction Amt.</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">payment_amount (CF)</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Refund (CF)</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">Status(Close)</td>
                                    <td className="py-4 text-sm font-semibold bg-gray-50  px-4 border-b">view details</td>
                                </tr>
                            </thead>
                            {loading ?
                                <Loader className="mx-auto" />
                                : (
                                    <tbody>
                                        {paymentRefundData?.getPaymentRes?.count > 0 && paymentRefundData?.getPaymentRes?.data && paymentRefundData?.getPaymentRes?.data.map((row, index) => (
                                            <tr key={index} className='hover:bg-gray-50'>
                                                <td className="py-2.5 text-sm px-4 border-b">{index + 1 + (page * limit)}</td>
                                                <td className="py-2.5 text-sm px-4 border-b">{moment(row?.createdAt).format('DD/MM/YYYY')}</td>
                                                <td className="py-2.5 text-sm px-4 border-b">{row?.orderDetails?.orderId}</td>
                                                <td className="py-2.5 text-sm px-4 border-b">{row?.orderDetails?.paymentDetails[0]?.cf_payment_id}</td>
                                                <td className="py-2.5 text-sm px-4 border-b">{row?.createdBy}</td>
                                                <td className="py-2.5 text-sm px-4 border-b">{row?.orderDetails?.awb_number}</td>
                                                {/* <td className="py-2.5 text-sm px-4 border-b">{row?.upiId}</td> */}
                                                <td className="py-2.5 text-sm px-4 border-b">{row?.cancelReason}</td>
                                                <td className="py-2.5 text-sm px-4 border-b">{row?.orderDetails?.order_amount}</td>
                                                <td className="py-2.5 text-sm px-4 border-b">{row?.orderDetails?.paymentDetails[0]?.payment_amount}</td>
                                                <td className="py-2.5 text-sm px-4 border-b"> <button onClick={() => {handleRefund(row?.orderDetails?.orderId,row?.orderDetails?.paymentDetails[0]?.payment_amount)}}
                                                    className="px-4 py-1 border text-white bg-green-600 rounded-md hover:bg-green-700 hover:text-white transition-colors duration-300"
                                                >
                                                    Refund
                                                </button></td>
                                                <td className="py-2.5 text-sm px-4 border-b"> <button onClick={() => { updateStatus(row?.id, false) }}
                                                    className="px-4 py-1 border border-slate-900 text-slate-900 rounded-md hover:bg-slate-900 hover:text-white bg-white transition-colors duration-300"
                                                >
                                                    Close
                                                </button></td>
                                                <td className="py-2.5 text-sm px-4 border-b text-blue-400 font-semibold"><Link to='/admintrack' state={{ id: row?.orderDetails?.id,orderId: row?.orderDetails?.orderId}}> View Details</Link></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                        </table>
                    </div>
                </div>
            </>
        )
    }

    export default AdminRefund
