import { gql } from "@apollo/client";

export const GET_ALL_USER = gql`
query Query($page: Int, $limit: Int, $filter: String, $sort: userSort, $search: String, $from: String, $to: String) {
  getAllUser(page: $page, limit: $limit, filter: $filter, sort: $sort, search: $search, from: $from, to: $to) {
    count
    data {
      id
      email
      userType
        verified
    ApprovePrivacyPolicy
      isActive
      createdAt
      updatedAt
    }
  }
}
`

export const GET_ROLE_USER = gql`
query Query {
  me {
    id
    email
    mobile
    userType
    isActive
    createdAt
    updatedAt
  }
}
`