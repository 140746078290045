import React, { useState, useEffect } from 'react';
import useScrollToTop from '../components/useScrollToTop.js';

const PriceCalculator = ({ orderAmount, paymentModal }) => {
    useScrollToTop();
    const [totalPrice, setTotalPrice] = useState(0);
    const [pickupCharge, setPickupCharge] = useState(0);
    const [handlingFee, setHandlingFee] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [discountPercentage, setDiscountPercentage] = useState(0);

    useEffect(() => {
        const calculatedPickupCharge = orderAmount * 0.60;
        const calculatedHandlingFee = orderAmount * 0.10;
        const calculatedTotalPrice = Math.round(orderAmount * 1.30) + calculatedPickupCharge + calculatedHandlingFee;
        const calculatedDiscount = calculatedTotalPrice - orderAmount;

        setTotalPrice(calculatedTotalPrice);
        setPickupCharge(calculatedPickupCharge);
        setHandlingFee(calculatedHandlingFee);
        setDiscount(calculatedDiscount);
        setDiscountPercentage(((calculatedTotalPrice - orderAmount) / calculatedTotalPrice) * 100);
    }, [orderAmount]);

    return (
        <div className=''>
            <p className='text-md font-semibold pb-4'>Price Details</p>
            <div className='space-y-3'>
                <div className='flex justify-between text-sm md:font-semibold '>
                    <p className='text-gray-500'>Total price</p>
                    <p className=''>+ ₹{Math.round(totalPrice)}</p>
                </div>
                <div className='flex justify-between text-sm md:font-semibold'>
                    <p className='text-gray-500'>Pickup Charge</p>
                    <div className='flex gap-x-1'>
                        <p className='line-through'>₹{Math.round(pickupCharge)}</p>
                        <p className='text-green-500'>Free</p>
                    </div>
                </div>
                <div className='flex justify-between text-sm md:font-semibold'>
                    <p className='text-gray-500'>Handling Fee</p>
                    <div className='flex gap-x-1'>
                        <p className='line-through'>₹{Math.round(handlingFee)}</p>
                        <p className='text-green-500'>Free</p>
                    </div>
                </div>
                <div className='flex justify-between text-sm md:font-semibold'>
                    <p className='text-gray-500'>Total Discounts</p>
                    <div className='flex space-x-3'>
                        <p>- ₹{Math.round(discount)} </p>
                        <p className='text-gray-500'>{Math.round(discountPercentage)}% off</p>
                    </div>
                </div>
                <p className='border'></p>
                {!paymentModal &&
                    <div className='flex justify-between text-sm md:font-semibold'>
                        <p>Total Amount</p>
                        <p>₹{Math.round(orderAmount)}</p>
                    </div>}
            </div>
        </div>
    );
};

export default PriceCalculator;







