import React from 'react'
import l7Image from '../asset/img/l77.jpg';
import l8Image from '../asset/img/l88.jpg';
import l1Image from '../asset/img/l1.jpg';
import l4Image from '../asset/img/l4.webp';
import l2Image from '../asset/img/l2.jpg';
import l3Image from '../asset/img/l3.webp';
import ll1 from '../asset/img/ll1.png';
import ll9 from '../asset/img/ll9.jpg';
import ll8 from '../asset/img/ll8.webp';
import ll2 from '../asset/img/ll2.jpg';
import useScrollToTop from '../components/useScrollToTop.js';


const Label = () => {
    useScrollToTop();

    return (
        <div>
            <div className='mx-auto max-w-auto px-4 sm:px-6 py-12 lg:px-8'>
                <div className='grid md:grid-cols-2 grid-cols-1'>
                    <div>
                        <p className='font-semibold mb-2'>Step 1: Paste shipment label on box</p>
                        <video
                            // controls
                            autoPlay
                            loop
                            muted
                            className="rounded-xl"
                        >
                            <source src="https://vaghasiyakishan111.s3.ap-south-1.amazonaws.com/longVideo/Untitled+1_1080p.mp4" type="video/mp4" className='cursor-pointer' />
                            Your browser does not support the video tag.
                        </video>

                    </div>
                    <div>
                        <video
                            // controls
                            autoPlay
                            loop
                            muted
                            className="rounded-xl h-80"
                        >
                            <source src="https://vaghasiyakishan111.s3.ap-south-1.amazonaws.com/longVideo/Untitled+2_1080p_2.mp4" type="video/mp4" className='cursor-pointer' />
                            Your browser does not support the video tag.
                        </video>
                    </div>

                </div>
                <div className='grid md:grid-cols-5 grid-cols-1 space-y-2 md:space-y-0 justify-between mb-2'>
                    <img src={l7Image} className='h-80' alt="" />
                    <img src={ll1} className='h-80' alt="" />
                    <img src={ll2} className='h-80' alt="" />
                    <img src={ll9} className='h-80' alt="" />
                    <img src={ll8} className='h-80' alt="" />
                </div>
                <div className='grid md:grid-cols-2 grid-cols-1 space-y-2 md:space-y-0 mb-2 md:mb-0'>
                    <img src={l1Image} alt="" />
                    <img src={l4Image} alt="" />
                </div>
                <div className='grid md:grid-cols-2 grid-cols-1 space-y-2 md:space-y-0'>
                    <img src={l2Image} alt="" />
                    <img src={l3Image} alt="" />
                </div>
                <h1 className="text-xl font-bold my-6">
                    What Is a Shipping Label and How It Works?
                </h1>
                <p className="text-gray-700 leading-relaxed mb-6">
                    In the intricate web of modern commerce, where goods traverse the globe with remarkable efficiency, a seemingly small yet vital component plays a pivotal role: the shipping label. At its core, a shipping label is more than just a piece of adhesive paper; it’s a key to unlocking the seamless movement of packages from sender to recipient.
                </p>
                <p className="text-gray-700 leading-relaxed mb-6">
                    This blog delves into the fundamental question: What is a shipping label? Unveiling its significance and inner workings, we will explore how this unassuming tag holds essential information, facilitates tracking, and ensures the successful delivery of items in our interconnected world of trade and e-commerce/ Home/ Office.
                </p>
                <h2 className="text-xl font-semibold mt-8 mb-4">What is a shipping label?</h2>
                <p className="text-gray-700 leading-relaxed mb-6">
                    A shipping label is a printed or electronic identification tag containing essential information about a package or parcel, facilitating efficient and accurate movement through the shipping and delivery process. It serves as a crucial communication tool between senders, recipients, and logistics providers, ensuring the proper handling and timely delivery of items.
                </p>

                <h3 className="text-lg font-semibold mt-6 mb-4">Below listed are the key features of shipping label : </h3>
                <ul className="list-disc list-inside space-y-4 text-gray-700 leading-relaxed">
                    <li><strong>Sender and Recipient Information:</strong>The shipping label prominently displays the sender’s and recipient’s complete names, addresses, and contact details. This not only aids in accurately identifying parties involved but also facilitates direct communication if necessary, mitigating potential delivery challenges.</li>
                    <li><strong>Barcodes and QR Codes:</strong> Integrating scannable codes, such as barcodes and QR codes, onto the shipping label holds paramount importance. These codes encode critical data, including unique tracking numbers and specific package details. When scanned, these codes facilitate automated sorting, tracking, and routing within the logistics network.</li>
                    <li><strong>Shipping Method and Service Level:</strong> A comprehensive shipping label explicitly specifies the selected shipping method (e.g., ground, express) and the chosen service level (e.g., standard, expedited). This information aids both carriers and recipients in accurately gauging the anticipated delivery timeline, thereby setting appropriate expectations.</li>
                    <li><strong>Tracking Number:</strong> Including a distinct tracking number on the label is central to the tracking and transparency of the shipping process. This tracking number serves as a distinctive identifier, empowering customers and carriers to monitor the package’s journey from its origin to its final destination.</li>
                    <li><strong>Package Weight and Dimensions:</strong>To optimize cargo space, calculate precise shipping costs, and ensure accurate handling, the shipping label provides pertinent details regarding the package’s weight and dimensions. This feature is particularly significant for ensuring efficiency in logistics operations.</li>
                    <li><strong>Fragile or Special Handling Instructions:</strong> In instances where the package contains delicate or fragile items, the shipping label can incorporate explicit warnings or instructions for special handling. This attention to detail helps prevent accidental damage during transit.</li>
                    <li><strong>Return Address:</strong> The shipping label prominently showcases a return address, an essential element for instances where the package encounters delivery challenges or requires return to the sender. This feature streamlines the process of resolving delivery issues and initiating returns.</li>
                    <li><strong>Customs Information:</strong> In international shipments, the shipping label may encompass pertinent customs-related data. This includes information about the package’s contents, value, and purpose, facilitating smoother customs clearance procedures at international borders.</li>
                    <li><strong>Carrier Logo and Information:</strong> The shipping label often incorporates the logo and contact details of the carrier responsible for the package’s transportation. This feature not only enhances the label’s professionalism but also equips recipients with the means to directly engage with the carrier when necessary.</li>
                    <li><strong>Order Details and Barcodes:</strong> Especially relevant in the realm of e-commerce, shipping labels can include order-specific details, such as order numbers, product names, and associated barcodes. These elements establish a connection to the customer’s purchase record, thereby simplifying order tracking and management processes.

                        In summary, shipping labels play a pivotal role in modern supply chain operations, optimizing logistics processes, enhancing package security, and elevating the overall customer experience. Through these in-depth features, shipping labels transform into invaluable tools that bridge the gap between various stakeholders and expedite the journey of packages from origin to destination.</li>
                </ul>

                <h2 className="text-xl font-semibold mt-8 mb-4">How Does a Shipping Label Work?</h2>
                <p className="text-gray-700 leading-relaxed mb-6">
                    A shipping label is a crucial component of the logistics process, serving as an identifier and information carrier for packages during transit. It contains essential details such as the sender’s and recipient’s addresses, package dimensions, weight, tracking number, and sometimes additional instructions or special handling requirements.
                </p>
                <p className="text-gray-700 leading-relaxed mb-6">
                    Shipping labels are generated by companies, individuals, or e-commerce platforms to ensure accurate and efficient delivery. Here are the top step-by-step details on how a shipping label works:
                </p>

                <h3 className="text-lg font-semibold mt-6 mb-4">Step-by-Step Process:</h3>
                <ul className="list-decimal list-inside space-y-4 text-gray-700 leading-relaxed">
                    <li><strong>Package Details:</strong> Gather all necessary information about the package, including its dimensions (length, width, height), weight, and a detailed description of its contents. This information helps determine the shipping cost, appropriate packaging, and any handling requirements.</li>
                    <li><strong>Sender’s Information:</strong> Provide the complete sender’s address, including the name, street address, city, state or province, ZIP or postal code, and a contact phone number. This ensures that the package can be returned or redirected if necessary.</li>
                    <li><strong>Recipient’s Information:</strong> Enter the complete recipient’s address, including the name, street address, city, state or province, ZIP or postal code, and a contact phone number. Accuracy is crucial to ensure the package reaches the right destination.</li>
                    <li><strong>Service Selection:</strong> Choose the shipping service that meets your needs, such as standard ground, express, overnight, or international shipping. Different services offer varying delivery speeds and costs, so select the one that aligns with your requirements.</li>
                    <li><strong>Package Insurance:</strong> Decide if you want to purchase package insurance. This coverage can protect you against damage, loss, or theft during transit. The cost of insurance typically depends on the package’s value.</li>
                    <li><strong>Label Generation:</strong> Use shipping software, an online platform, or a shipping provider’s website to generate the shipping label electronically. You’ll input the sender’s and recipient’s information, package details, and select the desired shipping service.</li>
                    <li><strong>Tracking Number:</strong> After generating the label, the system assigns a unique tracking number. This number enables real-time tracking and allows both the sender and recipient to monitor the package’s progress.</li>
                    <li><strong>Label Printing:</strong> Print the shipping label using a standard printer. Ensure that the label is clear, legible, and properly sized. Some platforms offer options to print directly onto adhesive paper or provide printable label sheets.</li>
                    <li><strong>Attachment and Packaging:</strong> Affix the printed label to the package securely. Make sure the label is fully visible and not obstructed by tape or packaging material. Place the item inside appropriate packaging, considering its fragility and the shipping method’s requirements.</li>
                    <li><strong>Drop-off or Pickup:</strong> Depending on the shipping method chosen, you may need to drop off the package at a designated drop-off location, such as a post office or shipping center. Alternatively, some shipping services offer package pickup options, where a carrier will collect the package from your location.</li>
                </ul>
            </div>
        </div>
    )
}

export default Label
