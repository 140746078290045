import { gql } from "@apollo/client";

export const INSERT_UPDATE_DELIVERY = gql`
mutation Mutation($input: DeliveryInput!) {
  insertUpdateDelivery(input: $input) {
    id
    name
    phone
    address1
    stateId
    city
    zipCode
    isDeleted
    isActive
    customerId
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`

export const DELETEDELIVERY = gql`
mutation DeleteDelivery($deleteDeliveryId: ID!) {
  deleteDelivery(id: $deleteDeliveryId) {
    id
    name
    phone
    address1
    stateId
    city
    zipCode
    isDeleted
    isActive
    customerId
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`