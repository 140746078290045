import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AdminHeader from './AdminHeader.js'
import { useQuery } from '@apollo/client'
import { GET_DAILY_COUNT_PAYMENTREFUND } from '../graphql/Query/paymentRefund.js'
import { GET_TOTAL_COUNT_DASHBOARD } from '../graphql/Query/Order.js'
import useScrollToTop from '../components/useScrollToTop.js';


const AdminPayment = () => {
  useScrollToTop();

    const { data } = useQuery(GET_DAILY_COUNT_PAYMENTREFUND);
    const { data: totalCount } = useQuery(GET_TOTAL_COUNT_DASHBOARD);

    const monthlyCountOrder = data?.getPaymentCounts?.monthlyPaymentRefund.reduce((acc, item) => acc + item.count, 0);
    const dailyCountOrder = data?.getPaymentCounts?.dailyPaymentRefund.reduce((acc, item) => acc + item.count, 0);
    return (
        <div>
            <AdminHeader />
            <div className='mx-auto max-w-auto px-6 lg:px-8'>
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6  py-10'>
                    <Link to="/adminrefund"> <div className='ring-1 ring-gray-300 hover:bg-gray-100 cursor-pointer p-4 bg-gray-50 space-y-3'>
                        <div className='space-y-3'>
                            <p className=' font-semibold'>ReFund Payment</p>
                            <div className='space-y-2'>
                                <p>Today : {dailyCountOrder} </p>
                                <p>Month : {monthlyCountOrder}</p>
                                <p>Total : {totalCount?.getDashboardCount?.payment}</p>
                            </div>
                        </div>
                        <p className='border border-gray-300'></p>
                        <p className='text-gray-400 font-semibold text-sm'>View Details</p>
                    </div> </Link>

                    <Link to='/adminincome'> <div className='ring-1 ring-gray-300 hover:bg-gray-100 cursor-pointer p-4 bg-gray-50 space-y-3'>
                        <div className='space-y-3'>
                            <p className='font-semibold'>Net Income</p>
                            <div className='space-y-2'>
                            </div>
                        </div>
                        <p className='border border-gray-300'></p>
                        <p className='text-gray-400 font-semibold text-sm'>View Details</p>
                    </div></Link>

                  


                </div>
            </div>
        </div>
    )
}

export default AdminPayment
