import React, { useState } from 'react';
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer.js';
import TrackForm from './TrackForm.js';
import TrackingHistoryModal from './TrackingHistoryModal.js';
import table from '../asset/img/table.png';
import useScrollToTop from '../components/useScrollToTop.js';


const TrackCourier = () => {
    useScrollToTop();

    const [trackingInfo, setTrackingInfo] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div className='bg-gray-50'>
            <Header />

            <div className="relative isolate overflow-hidden space-y-10 bg-gray-900 py-16 sm:py-14">
                <div className="mx-auto flex flex-col lg:flex-row gap-x-3 gap-y-10 max-w-auto md:px-8 lg:px-8">
                    <TrackForm setTrackingInfo={setTrackingInfo} setModalOpen={setModalOpen} />
                </div>
                <div className='mx-auto max-w-auto md:px-8 lg:px-8'>
                    <div className='bg-white rounded-lg py-7 px-10'>
                        <p className='font-semibold text-lg mb-3'>Fastest and reliable shipping, anywhere in India</p>
                        <p>The most trusted partner for your shipping requirements with pick-up and delivery of products from warehouses and sellers to end-consumers using automated infrastructure and information systems.</p>
                        <p className='font-semibold text-lg my-3'>Shipping Services</p>
                        <p className='text-slate-700'>● Tech-enabled pick-up and delivery services to whole of India</p>
                        <p className='text-slate-700'>● Supported by 150+ gateways, hubs and fulfillment centers across 200+ cities</p>

                        <p className='font-semibold text-lg my-5'>Powerful AI for next-generation logistics solutions</p>
                        <p className='text-slate-700'>● AI-enabled address engine for precise delivery location
                            Company uses advanced AI function for a finely-tuned location approach to categorize orders with pinpoint precision. This targeted allocation eliminates the possibility of misrouted deliveries leading to an efficient and faster order fulfillment.
                        </p>
                        <p className='text-slate-700 my-5'>●
                            AI-enhanced routes for optimized deliveries
                            Company leverages AI to provide routes that are the fastest and the most optimal. Our AI-powered route recommendations ensure that deliveries reach their destination with unmatched efficiency.</p>
                        <p className='text-slate-700'>● AI-predictive demand for efficient distribution network
                            Ecom Express leverages artificial intelligence to predict demand patterns and anticipate shipping requirements. Our AI technology guides us towards the optimal locations for new distribution centers to fulfill our partner's logistics needs.</p>

                        <p className='my-3 font-semibold'>Trusted logistics partner for all Home-Office needs</p>
                        <p className='font-semibold'>Trusted by more than 2.5 lakh large and small merchant for faster deliveries, expanding business, reliable shipping experience.</p>
                    </div>
                </div>
            </div>
            <div className="mx-auto max-w-auto md:p-6 lg:px-8">
                <img src={table} alt="" />
            </div>
            <Footer />
            {modalOpen && trackingInfo && (
                <TrackingHistoryModal
                    trackingInfo={trackingInfo}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                />
            )}
        </div>
    );
};

export default TrackCourier;
