import React from 'react'
import Header from '../Header/Header.js'
import Accordion from './Accodion.js';
import Footer from '../Footer/Footer.js';
import Mainone from './Mainone.js';
import Typefive from './Typefive.js';
import Typefour from './Typefour.js';
import Typesix from './Typesix.js';
import './Accodion.css'
import useScrollToTop from '../components/useScrollToTop.js';



const Home = () => {
    useScrollToTop();

    return (
        <div>
            <Header />

            <div className="relative isolate overflow-hidden bg-gray-900 pt-4 pb- mb-0.5 sm:pb- sm:pt-9">
                <video
                    // controls
                    autoPlay
                    loop
                    muted
                    className="absolute inset-0 brightlight -z-10 h-full w-full object-cover object-right md:object-center"
                >
                    <source src="https://vaghasiyakishan111.s3.ap-south-1.amazonaws.com/longVideo/Untitled_1080p_4.mp4" type="video/mp4" className='cursor-pointer'/>
                    Your browser does not support the video tag.
                </video>

                <div className="mx-auto max-w-auto lg:px-8 px-6 text-white cursor-pointers">
                    <p className='font-bold text-3xl text-gray-100'>UK Logistics</p>
                    <p className='font-semibold text-xl mt-3 text-gray-200'>Domestic and International Shipping Services</p>
                    {/* <p className='text-lg mt-12 text-gray-300'>We offer door-to-door, port-to-port express and freight shipping services working directly with global airlines and ocean liners.</p>
                <p className='text-lg my-14 text-gray-300'>Our capabilities include an integrated network of express and freight solutions coupled with supplementary solutions of tech-enabled tracking, an in-house regulatory team for proactive clearance support, and a dedicated customer servicing team.</p>
                <p className='text-lg mt-7 text-gray-300'>We are FedEx’s strategic partners in India for global express shipping services.</p> */}
                    <p className='text-end mt-56 mb-3'>www.uk-logistic.com</p>
                </div>
            </div>
            <Mainone />
            <Typesix />

            <Typefive />
            <Typefour />
            <Accordion />
            <div className='mt-14'>
                <Footer />
            </div>
        </div>
    )
}

export default Home
