import { gql } from "@apollo/client";

export const GET_DELIVERY_ADDRESS=gql`
query Query($page: Int, $limit: Int, $filter: String, $sort: orderSort, $customerId: ID) {
  getDeliveriesOfCustomer(page: $page, limit: $limit, filter: $filter, sort: $sort, customerId: $customerId) {
    count
    data {
      id
      name
      phone
      address1
      stateId
      city
      zipCode
      isDeleted
      isActive
      customerId
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
}
`