import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ORDER_MENIFETCH, REFUND_ORDER_MUTATION } from '../graphql/Mutation/Ordre.js';
import SpinLoader from '../components/SpinLoader.js';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import { API_URL } from '../config.js';
import gifs from '../asset/img/successgif.png';
import cancel from '../asset/img/cancel.png';

const SuccessPayment = () => {
  const navigate = useNavigate();
  const [orderMenifetch] = useMutation(ORDER_MENIFETCH);
  const [paymentStatus, setPaymentStatus] = useState('');
  // console.log('✌️paymentStatus --->', paymentStatus);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const orderId = query.get('order_id');
  const callMade = useRef(false);

  const ordermenifetchData = async () => {
    // console.log("calll=========================")
    let isCalled = false;
    if (paymentStatus === 'Success' && orderId && !isCalled) {
      // console.log("calll=========================2222222")

      localStorage.removeItem('modalOpenedOnce');
      isCalled = true;
      // console.log("calll=====================00000000000000++++++++++")
      // const callOrderMenifetch = async () => {
      try {
        const { data } = await orderMenifetch({
          variables: {
            orderId: orderId
          }
        });
        if (data?.orderMenifetch) {
          setTimeout(() => {
            navigate('/allOrders', { state: { shouldRefetch: true } });
          }, 500);
        }
      } catch (error) {
      }
      // };
      // callOrderMenifetch();
    } else
      if (paymentStatus === 'Failure') {
        toast.error('Payment failed. Please try again.')
        setTimeout(() => {
          navigate('/allOrders', { state: { shouldRefetch: false } });
        }, 500);
      } else if (paymentStatus === 'Pending') {
        toast.error('Payment pending. Please try again.')
        setTimeout(() => {
          navigate('/allOrders', { state: { shouldRefetch: false } });
        }, 500);
      }
    // }, [paymentStatus, orderId]);
  }
  const fetchPaymentStatus = async () => {
    try {
      // const response = await fetch(`https://api.uk-logistic.com/order-status/${orderId}`);
      const response = await fetch(`${API_URL}/order-status/${orderId}`);
      // console.log('✌️response --->', response);
      const data = await response.json();
      // console.log('✌️data --->', data);
      let getOrderResponse = data;
      let orderStatus;

      if (getOrderResponse.filter(transaction => transaction.payment_status === "SUCCESS").length > 0) {
        orderStatus = "Success"

        // console.log("calll=====================11111111111111")
      } else if (getOrderResponse.filter(transaction => transaction.payment_status === "PENDING").length > 0) {
        orderStatus = "Pending"

        // console.log("llllllllllllll")
      } else {
        orderStatus = "Failure"
        // console.log('✌️Failure --->');

      }

      setPaymentStatus(orderStatus);
    } catch (error) {
      // console.log('✌️error --->', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // console.log("calll=====================+")
    if (orderId && !callMade.current) {
      callMade.current = true;
      fetchPaymentStatus();
    }
  }, [orderId]);
  useEffect(() => {
    if (!loading && paymentStatus) {
      // console.log("--------------------------------")
      ordermenifetchData();
    }
  }, [paymentStatus, loading]);

  const backgroundColor = loading ? 'bg-white' :
    paymentStatus === 'Success' ? 'bg-green-700' :
      paymentStatus === 'Pending' ? 'bg-white' :
        'bg-red-900';
  return (
    <div className={`${backgroundColor} h-screen w-screen flex justify-center items-center`}>
      <div className='text-center'>
        {loading ? (

          <SpinLoader classNamecolor='true' />

        ) : (
          <>
            {paymentStatus === 'Success' && <img src={gifs} className='animate-bounce drop-shadow-2xl mx-auto' alt="" />}
            {paymentStatus === 'Failure' && <img src={cancel} className='animate-bounce drop-shadow-2xl mx-auto' alt="" />}
            <p className='font-semibold text-white text-3xl mb-6'>Courier {paymentStatus}!</p>
            <div> <Link to="/allOrders"> <button
              className="py-2.5 px-3 w-full inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-white text-black hover:bg-white focus:outline-none text-sm"
            >
              {/* <FaCalculator className="text-white me-2 text-lg" /> */}
              Go to All Courier            </button></Link></div>


            {/* {paymentStatus === 'Success' && <Lottie animationData={l} loop={false} className='h-64 w-64' />}
            {paymentStatus === 'Failure' && <Lottie animationData={cancelblack} loop={false} className='h-64 w-64' />}
            {paymentStatus === 'Pending' && <Lottie animationData={pending} loop={false} className='h-64 w-64' />} */}
          </>
        )}
      </div>
    </div>
  );
}

export default SuccessPayment;
