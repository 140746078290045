import { gql } from "@apollo/client";

export const ORDER_COMMITION = gql`
query GetCommitionAmount {
  getCommitionAmount {
    order_percentage
    isActive
  }
}
`

export const INSERT_COMMITION = gql`
mutation UpsertCommition($orderPercentage: Float) {
  upsertCommition(order_percentage: $orderPercentage) {
    order_percentage
    isActive
  }
}
`