import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaImage, FaVideo } from 'react-icons/fa';
import AdminHeader from './AdminHeader.js';
import { ADD_IMG_VID_OPTION, DELETE_IMG_VID_OPTION, GET_IMG_VID_OPTIONS, UPDATE_IMG_VID_OPTION } from '../graphql/Mutation/carousel.js';

const validationSchema = Yup.object({
  image: Yup.mixed().required('Image is required'),
});

const Carousel = () => {
  const { loading, error, data, refetch } = useQuery(GET_IMG_VID_OPTIONS, {
    variables: { page: 1, limit: 10 },
  });

  const [addImgVidOption] = useMutation(ADD_IMG_VID_OPTION);
  const [updateImgVidOption] = useMutation(UPDATE_IMG_VID_OPTION);
  const [deleteImgVidOption] = useMutation(DELETE_IMG_VID_OPTION);

  const formik = useFormik({
    initialValues: {
      image: null,
      video: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const result = await addImgVidOption({ variables: { input: values } });
        refetch();
      } catch (err) {
        console.error('Error adding:', err);
      }
    },
  });

  const handleFileChange = (e, field) => {
    formik.setFieldValue(field, e.currentTarget.files[0]);
  };

  const handleUpdate = async (values) => {
    try {
      const result = await updateImgVidOption({ variables: { input: values } });
      refetch(); // Refetch data after updating
    } catch (err) {
      console.error('Error updating:', err);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await deleteImgVidOption({ variables: { id } });
      refetch(); // Refetch data after deleting
    } catch (err) {
      console.error('Error deleting:', err);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <AdminHeader />
      <div className="mx-auto max-w-3xl px-2 sm:px-6 lg:px-8 my-10 space-y-6">
        <h2 className="text-xl font-bold text-center">Advertisement To Customer (Notification)</h2>
        <form
          onSubmit={formik.handleSubmit}
          className="p-5 space-y-6 bg-gray-50 border rounded-lg"
        >
          <div className="">
            <p className="text-sm font-medium text-gray-700 ms-6">Upload Image</p>
            <div className="flex items-center justify-center space-x-2 mt-2">
              <FaImage />
              <input
                type="file"
                name="image"
                onChange={(e) => handleFileChange(e, 'image')}
                className="ring-1 ring-inset ring-gray-300 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 w-full px-3.5 py-2 border rounded-md"
              />
            </div>
            {formik.touched.image && formik.errors.image ? (
              <div className="text-red-600 text-sm">{formik.errors.image}</div>
            ) : null}
          </div>
          <div className="">
            <p className="text-sm font-medium text-gray-700 ms-6">Upload Video</p>
            <div className="flex items-center justify-center space-x-2 mt-2">
              <FaVideo />
              <input
                type="file"
                name="video"
                onChange={(e) => handleFileChange(e, 'video')}
                className="ring-1 ring-inset ring-gray-300 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 w-full px-3.5 py-2 border rounded-md"
              />
            </div>
            {formik.touched.video && formik.errors.video ? (
              <div className="text-red-600 text-sm">{formik.errors.video}</div>
            ) : null}
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="block w-full rounded-md bg-slate-700 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-slate-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
            >
              Upload carousel
            </button>
          </div>
        </form>
        <div>
          {data.getImgVidOption.map((option) => (
            <div key={option.id} className="border p-4 rounded-md my-4">
              <img src={option.image} alt="Image" className="w-full h-auto" />
              <video src={option.video} controls className="w-full h-auto mt-4" />
              <div className="flex justify-end space-x-2 mt-2">
                <button
                  onClick={() => handleUpdate(option)}
                  className="px-4 py-2 bg-blue-500 text-white rounded-md"
                >
                  Update
                </button>
                <button
                  onClick={() => handleDelete(option.id)}
                  className="px-4 py-2 bg-red-500 text-white rounded-md"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
