import React, { useEffect, useState, useRef } from 'react'
import Header from '../Header/Header.js'
import Footer from '../Footer/Footer.js'
import { GoCircle } from "react-icons/go";
import { SlLocationPin } from "react-icons/sl";
import { GET_ORDERS_BY_ID } from '../graphql/Query/Order.js';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import { CANCEL_ORDER } from '../graphql/Mutation/Ordre.js';
// import { toast } from 'react-toastify';
import PriceCalculator from '../DocumentShiping/priceCalculator.js';
import Invoice from '../Common/Invoice.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { useModal } from '../Contex.js';
import Accordion from '../Home/Accodion.js';
import { handleLogin } from '../Common/TokenLogin.js';
import { BsBoxSeam } from "react-icons/bs";
import { SlClose } from "react-icons/sl";
import { MdDirectionsBike } from "react-icons/md";
import { LiaTruckMovingSolid } from "react-icons/lia";
import { FaRegCheckCircle } from "react-icons/fa";
import useScrollToTop from '../components/useScrollToTop.js';


const SingleTrack = () => {
    useScrollToTop();

    const iconRefs = useRef([]);
    const [lineHeights, setLineHeights] = useState([]);
    const { openModal } = useModal();
    const [cancelCourier] = useMutation(CANCEL_ORDER);
    const [showMore, setShowMore] = useState(false);
    const [shipmentData, setShipmentData] = useState(null);
    // console.log('✌️shipmentData --->', shipmentData);
    const location = useLocation();
    const getId = location?.state?.id
    const activeButton = location?.state?.activeButton
    const { data: GetOrderOrder, refetch } = useQuery(GET_ORDERS_BY_ID, {
        variables: {
            "getOrderId": getId
        }
    });
    useEffect(() => {
        const heights = iconRefs.current.map((ref, index) => {
            if (index < iconRefs.current.length - 1) {
                const currentIcon = ref.getBoundingClientRect();
                const nextIcon = iconRefs.current[index + 1].getBoundingClientRect();
                return nextIcon.top - currentIcon.bottom;
            }
            return 0;
        });
        setLineHeights(heights);
    }, [shipmentData, showMore]);

    const handleDownload = (label) => {
        const link = document.createElement('a');
        link.href = label;
        link.download = 'label.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const handleDownloadInvoice = () => {
        const invoiceElement = document.getElementById('invoice');
        if (!invoiceElement) {
            return;
        }

        invoiceElement.style.visibility = 'visible';
        invoiceElement.style.position = 'static';

        html2canvas(invoiceElement, { scale: 2 })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save(`invoice_${GetOrderOrder?.getOrder?.order_id}.pdf`);

                invoiceElement.style.visibility = 'hidden';
                invoiceElement.style.position = 'absolute';
            })
            .catch((error) => console.error('Error generating PDF:',));
    };

    // const [ setDeliveryData] = useState(null);

    const capitalizeFirstLetter = (text) => {
        if (!text) return text;
        return text.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
    };


    useEffect(() => {
        const trackShipment = async () => {
            const authToken = await handleLogin();
            const awb = GetOrderOrder?.getOrder?.awb_number;
            const url = `https://shipment.xpressbees.com/api/shipments2/track/${awb}`;
            try {
                if (GetOrderOrder?.getOrder?.awb_number && awb) {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authToken}`,
                        }
                    });


                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const data = await response.json();
                    setShipmentData(data);
                }

            } catch (err) {

            }
        };
        trackShipment();
    }, [GetOrderOrder?.getOrder?.awb_number])


    const handleToggle = () => setShowMore(!showMore);
    let statusOrder;

    switch (shipmentData?.data?.status) {
        case 'cancelled':
            statusOrder = ['PP', 'CL'];
            break;
        case 'rto':
            statusOrder = ['PP', 'RT'];
            break;
        default:
            statusOrder = ['PP', 'IT', 'OFD', 'DL'];
            break;
    }

    const getLatestStatusCode = (history) => {
        if (history.length > 0) {
            // console.log("history[history.length - 1].status_code",history[history.length - 1].status_code,history[history.lengt])
            // return history[history.length - 1].status_code;
            // console.log("history[0].status_code", history[0].status_code);
            return history[0].status_code;
        }
        return null;
    };

    const visibleStatuses = showMore ? statusOrder : statusOrder.slice(0, 3);

    const getStatus = (status) => {
        switch (status) {
            case 'PP':
                return 'Courier Confirmed';
            case 'IT':
                return 'In Transit';
            case 'OFD':
                return 'Out for Delivery';
            case 'DL':
                return (<><p>Delivery Successfully</p><p className="text-sm text-gray-500 font-normal">Your item has been delivered</p></>);
            case 'CL':
                return shipmentData?.data?.status === "cancelled" ? <> <p>Courier Cancelled</p> <p className='text-xs text-gray-500'>Your order has been cancelled.</p> <p className='text-xs text-gray-500'>You have cancelled this order.</p> </> : '';
            case 'RT':
                return shipmentData?.data?.status === "rto" ? <> <p>Courier RTO</p> <p className='text-xs text-gray-500'>Your order has been cancelled.</p> <p className='text-xs text-gray-500'>You have cancelled this order.</p> </> : '';
            default:
                return '';
        }
    };


    const getIconColor = (history, targetStatus, isCancelled, rto) => {
        // console.log('✌️history, targetStatus, isCancelled, rto --->', history, targetStatus, isCancelled, rto);
        if (isCancelled && targetStatus === 'CL') {
            return 'bg-red-500 border-red-200';
        }
        if (rto && targetStatus === 'RT') {
            return 'bg-red-500 border-red-200';
        }

        const latestStatusCode = getLatestStatusCode(history);
        // console.log('✌️latestStatusCode --->', latestStatusCode);
        const latestIndex = statusOrder.indexOf(latestStatusCode);
        // console.log('✌️latestIndex --->', latestIndex);
        const targetIndex = statusOrder.indexOf(targetStatus);
        // console.log('✌️targetIndex --->', targetIndex);

        if (latestIndex >= targetIndex) {
            return 'bg-green-500 border-green-200';
        } else {
            return 'bg-gray-400 border-gray-200';
        }
    };
    const getLineColor = (history, targetStatus, isCancelled, rto) => {
        if (isCancelled && targetStatus === 'CL') {
            return 'bg-green-400';
        }
        if (isCancelled && targetStatus === 'RT') {
            return 'bg-green-400';
        }

        const latestStatusCode = getLatestStatusCode(history);
        // console.log('✌️latestStatusCode --->', latestStatusCode);
        const latestIndex = statusOrder.indexOf(latestStatusCode);
        // console.log('✌️latestIndex --->', latestIndex);
        const targetIndex = statusOrder.indexOf(targetStatus);
        // console.log('✌️targetIndex --->', targetIndex);

        if (latestIndex >= targetIndex) {
            return 'bg-green-500';
        } else {
            return 'bg-gray-400';
        }
    };

    return (
        <div className='bg-gray-0'>
            <Header />

            <div className='mx-auto md:pt-7 pt-9 pb-9 max-w-auto lg:px-8'>
                <div className='border border-gray-300 p-2 rounded-md md:space-y-6 space-y-9'>
                    <div className='px-4'>
                        <p className='text-2xl mt-4 font-bold'>Track Courier Details</p>
                        <p className={`text-md font-semibold ${GetOrderOrder?.getOrder?.trackStatus === 'Your courier has been confirmed' ? 'text-green-500' : 'text-red-500'}`}>
                            {GetOrderOrder?.getOrder?.trackStatus}
                        </p>
                    </div>
                    <div className='flex flex-wrap justify-between px-4'>
                        <div className='space-y-2'>
                            <p className='text-sm font-semibold text-gray-500'>Order ID <span>{GetOrderOrder?.
                                getOrder
                                ?.order_id}</span></p>
                            <p className='text-sm font-semibold text-gray-500'>Order No. <span>{GetOrderOrder?.
                                getOrder
                                ?.order_number}</span></p>
                            <p className='text-sm font-semibold text-slate-900'>AWB NO. <span>{GetOrderOrder?.
                                getOrder
                                ?.awb_number}</span></p>
                            <p className='text-sm text-gray-500'>{moment(GetOrderOrder?.
                                getOrder
                                ?.createdAt).format('DD/MM/YYYY')}</p>
                        </div>
                        <div className='md:space-x-4 space-y-4 md:mt-7 lg:mt-0'>
                            <Link to="/accordionhelp">
                                <button
                                    className='px-9 py-1 mr-4 md:mr-0 rounded-md bg-slate-900 text-white'
                                >
                                    Help
                                </button>
                            </Link>
                            {GetOrderOrder?.getOrder?.awb_number && GetOrderOrder?.getOrder?.trackStatus === 'Your courier has been confirmed' && activeButton === 'All Courier' &&
                                <button
                                    onClick={() => {
                                        handleDownload(GetOrderOrder?.
                                            getOrder
                                            ?.label)
                                    }}
                                    className='px-3 py-1 rounded-md bg-green-600 hover:bg-green-700 text-white'
                                >
                                    Download Label
                                </button>
                            }
                            {GetOrderOrder?.getOrder?.awb_number && GetOrderOrder?.getOrder?.trackStatus === 'Your courier has been confirmed' &&

                                <button
                                    className='px-3 py-1 mr-4 md:mr-0 rounded-md bg-slate-900 text-white'
                                    onClick={handleDownloadInvoice}
                                >
                                    Download Invoice
                                </button>
                            }
                            { (shipmentData?.data && shipmentData?.data?.status !== "in transit" && shipmentData?.data?.status !== "delivered" && shipmentData?.data?.status !== "out for delivery" )
                            && GetOrderOrder?.getOrder?.awb_number && GetOrderOrder?.getOrder?.trackStatus === 'Your courier has been confirmed' && activeButton === 'All Courier'  ?
                                <button
                                    className='px-3 py-1 rounded-md border border-red-500 text-red-500'
                                    onClick={() => openModal(GetOrderOrder?.getOrder?.awb_number, getId)}
                                >
                                    Cancel Courier
                                </button>
                                :""
                            }
                             {/* <button
                                    className='px-3 py-1 rounded-md border border-red-500 text-red-500'
                                    onClick={() => openModal(GetOrderOrder?.getOrder?.awb_number, getId)}
                                >
                                    Cancel Courier
                                </button> */}
                        </div>
                    </div>
                    <div className='grid gap-x-3 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-3'>
                        <div className='space-y-4 p-5 border border-gray-300 rounded-md'>
                            <p className='text-md font-semibold'>Address Details</p>
                            <div className='flex space-x-4'>
                                <div className='space-y-1 text-gray-400'>
                                    <GoCircle />
                                    <ul className='ms-1.5'>
                                        {[...Array(8)].map((_, i) => (
                                            <li key={i} className={`text-xs leading-3 ${i >= 7 ? 'sm:hidden' : ''}`}>I</li>
                                        ))}
                                    </ul>
                                    <SlLocationPin />
                                </div>
                                <div className='space-y-4 w-full  text-ellipsis overflow-hidden'>
                                    <div>
                                        <p className='text-sm font-semibold mb-1'>
                                            {capitalizeFirstLetter(GetOrderOrder?.getOrder?.pickupAddress?.name)}
                                            <span className='ms-1 text-gray-600'>(Pickup)</span>
                                        </p>
                                        <p className='text-sm truncate'>
                                            {capitalizeFirstLetter(GetOrderOrder?.getOrder?.pickupAddress?.address1)},
                                            <p className='text-sm truncate'>{capitalizeFirstLetter(GetOrderOrder?.getOrder?.pickupAddress?.city)}. {capitalizeFirstLetter(GetOrderOrder?.getOrder?.pickupAddress?.stateId?.name)} - {GetOrderOrder?.getOrder?.pickupAddress?.zipCode}</p>
                                        </p>
                                        <p className='text-sm text-gray-500 truncate'>
                                            {GetOrderOrder?.getOrder?.pickupAddress?.phone}
                                        </p>
                                    </div>
                                    <div>
                                        <p className='text-sm font-semibold mb-1'>
                                            {capitalizeFirstLetter(GetOrderOrder?.getOrder?.deliveryAddress?.name)}
                                            <span className='ms-1 text-gray-600'>(Delivery)</span>
                                        </p>
                                        <p className='text-sm truncate'>
                                            {capitalizeFirstLetter(GetOrderOrder?.getOrder?.deliveryAddress?.address1)},
                                            <p className='text-sm truncate'> {capitalizeFirstLetter(GetOrderOrder?.getOrder?.deliveryAddress?.city)}. {capitalizeFirstLetter(GetOrderOrder?.getOrder?.deliveryAddress?.stateId?.name)} - {GetOrderOrder?.getOrder?.deliveryAddress?.zipCode}</p>
                                        </p>
                                        <p className='text-sm text-gray-500 truncate'>
                                            {GetOrderOrder?.getOrder?.deliveryAddress?.phone}
                                        </p>
                                    </div>
                                </div>

                            </div>

                            <p className="text-md font-semibold mb-4">Package Details</p>
                            <div className='flex justify-between'>
                                <p className='text-sm font-semibold text-gray-500'>Weight (Kg)</p>
                                <p className='text-sm font-semibold'>{GetOrderOrder?.getOrder?.package_weight_Length}</p>
                            </div>
                            <div className='flex justify-between'>
                                <p className='text-sm font-semibold text-gray-500'>Product Category</p>
                                <p className='text-sm font-semibold'>{GetOrderOrder?.getOrder?.ProductName}</p>
                            </div>
                            <div className='flex justify-between'>
                                <p className='text-sm font-semibold text-gray-500'>Product Value</p>
                                <p className='text-sm font-semibold'>₹{GetOrderOrder?.getOrder?.ProductPrice}</p>
                            </div>
                        </div>

                        {shipmentData && (
                            <div className="w-full mx-auto p-5 border border-gray-300 rounded-md bg-white">
                                <p className="text-md font-semibold mb-4">Courier Tracking</p>
                                <div className="relative flex flex-col items-start space-y-8">
                                    {statusOrder.map((status, index) => {
                                        const isCancelled = shipmentData?.data?.status === "cancelled";
                                        const rto = shipmentData?.data?.status === "rto";

                                        return (
                                            <React.Fragment key={index}>
                                                <div className="flex items-center space-x-4">
                                                    <div className="relative">
                                                        <div
                                                            ref={(el) => iconRefs.current[index] = el}
                                                            className={`${getIconColor(shipmentData?.data?.history, status, isCancelled, rto)} ${shipmentData?.data?.status === "pending pickup" && status === 'PP' ? 'bg-green-500 border-green-200' : ''} border-4 text-white p-1.5 rounded-full`}
                                                        >
                                                            {status === 'PP' && <BsBoxSeam />}
                                                            {status === 'IT' && <LiaTruckMovingSolid />}
                                                            {status === 'OFD' && <MdDirectionsBike />}
                                                            {status === 'DL' && <FaRegCheckCircle />}
                                                            {status === 'CL' && isCancelled && <SlClose />}
                                                            {status === 'RT' && rto && <SlClose />}
                                                        </div>
                                                        {index < statusOrder.length - 1 && (
                                                            <div
                                                                style={{ height: `${lineHeights[index]}px` }}
                                                                className={`${getLineColor(shipmentData?.data?.history, statusOrder[index + 1], isCancelled, rto)} absolute left-1/2 transform -translate-x-1/2 w-px`}
                                                            />
                                                        )}
                                                    </div>
                                                    <div>
                                                        <h2 className="font-medium text-sm">{getStatus(status)}</h2>
                                                        <p className="text-sm text-gray-500">
                                                            {shipmentData?.data?.history.find(hist => hist.status_code === status)?.event_time || ''}
                                                        </p>
                                                    </div>
                                                </div>

                                                {!isCancelled && showMore && shipmentData?.data?.history
                                                    .filter(hist => hist.status_code === status)
                                                    .reverse()
                                                    .map((hist, histIndex) => (
                                                        <div key={histIndex} className="flex items-start ml-8 space-x-4">
                                                            <div className="w-px bg-gray-300" style={{ height: 'auto' }}></div>
                                                            <div>
                                                                <p className="text-sm font-semibold">{hist.event_time}</p>
                                                                <p className="text-sm text-gray-500">{hist.location}</p>
                                                                <p className="text-sm text-gray-500">{hist.message}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                                <button onClick={handleToggle} className="mt-7 text-black ml-7 flex font-semibold text-sm focus:outline-none">
                                    {showMore ? 'SHOW LESS' : 'SHOW MORE'} <span className='self-center text-xl'>{showMore ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
                                </button>
                            </div>
                        )}

                        <div className='p-5 border border-gray-300 rounded-md'>
                            <PriceCalculator orderAmount={GetOrderOrder?.getOrder?.order_amount} />
                        </div>


                    </div>

                    <div id="hidden-invoice-container" style={{ visibility: 'hidden', position: 'absolute', top: '-9999px' }}>
                        <Invoice order={GetOrderOrder?.getOrder} />
                    </div>
                </div>


            </div>

            <Accordion />
            <div className='mt-16'>

                <Footer />
            </div>
        </div>
    )
}

export default SingleTrack
