import React, { useState, useEffect } from 'react';

const Loader = () => {
  const [color, setColor] = useState('bg-black');

  useEffect(() => {
    const interval = setInterval(() => {
      setColor((prevColor) => (prevColor === 'bg-black' ? 'bg-white' : 'bg-black'));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex justify-center align-middle ">
      <div className="flex space-x-2">
        <div className={`w-3 h-3 ${color} rounded-full animate-bounce`}></div>
        <div className={`w-3 h-3 ${color} rounded-full animate-bounce delay-150`}></div>
        <div className={`w-3 h-3 ${color} rounded-full animate-bounce delay-300`}></div>
      </div>
    </div>
  );
};

export default Loader;
