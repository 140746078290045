import React, { useState } from 'react'
import AdminHeader from './AdminHeader.js'
import NoDataFound from '../components/NoDataFound.js'
import { useQuery } from '@apollo/client'
import { GET_NETAMOUNT, GETNETAMOUNT, GETTURNOVER } from '../graphql/Query/Order.js'
import CustomPagination from '../components/Pagination.js'
import useScrollToTop from '../components/useScrollToTop.js';



const AdminIncome = () => {
  useScrollToTop();

  const { data } = useQuery(GETTURNOVER);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(200);
  const [searchUser, setSearchUser] = useState('');
  const [toDate, setToDate] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [filter, setFilter] = useState('');
  const { data: getNetAMount } = useQuery(GET_NETAMOUNT);
  const { data: getNetIncome, loading, error, refetch } = useQuery(GETNETAMOUNT, {
    variables: {
      page: page + 1,
      limit,
      sort: {
        key: "createdAt",
        type: -1
      },
      filter: filter,
            from: fromDate,
            to: toDate,
    }
  });



  const fromDateChange = (e) => {
    setFromDate(e.target.value);
}
const toDateChange = (e) => {
    setToDate(e.target.value)
}
  const searchCustomerUser = () => {
    setFilter(searchUser)
}
const handleSearch = (e) => {
    searchCustomerUser()
}

  const handleReset = () => {
    setFromDate('');
    setToDate('');
    setFilter('');
    setSearchUser('');
}
const handlePageClick = (selectedPage) => {
  setPage(selectedPage);
};
  return (
    <div>
      <AdminHeader />
      <div className='mx-auto max-w-auto px-6 lg:px-8 my-10 space-y-7'>
        <div className='grid grid-cols-4 space-x-2'>
          <div className='ring-1 ring-gray-300 p-4 space-y-3'>
            <div className='space-y-3'>
              <p className='font-semibold'>Net Turnover</p>
              <div className='space-y-2'>
                <p>Today Turnover : {data?.orderAmountTotals?.dailyTotal} </p>
                <p>Month Turnover : {data?.orderAmountTotals?.monthlyTotal}</p>
                <p>Year Turnover : {data?.orderAmountTotals?.yearlyTotal}</p>
              </div>
            </div>
            <p className='border border-gray-300'></p>
            <p className='text-gray-400 font-semibold text-sm'>View Details</p>
          </div>

          {/* Net Income Card */}
          <div className='ring-1 ring-gray-300 p-4 space-y-3'>
            <div className='space-y-3'>
              <p className='font-semibold'>Net Income</p>
              <p className='font-semibold'>(-Third party, -Cashfree)</p>
              <div className='space-y-2'>
                <p>Today : {getNetAMount?.orderNetAmountTotals?.dailyTotal} </p>
                <p>Month : {getNetAMount?.orderNetAmountTotals?.monthlyTotal} </p>
                <p>Year : {getNetAMount?.orderNetAmountTotals?.yearlyTotal} </p>
              </div>
            </div>
            <p className='border border-gray-300'></p>
            <p className='text-gray-400 font-semibold text-sm'>View Details</p>
          </div>
        </div>

        {/* Filters Section */}
        <div className='p-4 max-w-full mx-auto bg-gray-50 ring-1 ring-gray-200 rounded-xl space-y-4'>
          <div className='flex justify-between'>
            <h2 className="text-lg font-semibold">Orders Filters</h2>
            <button
              className="px-4 py-1 border border-slate-900 text-slate-900 rounded-md hover:bg-slate-900 hover:text-white bg-white transition-colors duration-300"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
          <div className='lg:flex space-y-7 lg:space-y-0 lg:justify-between'>
            <div>
              <p className="text-sm font-medium text-gray-700">Search</p>
              <div className='md:flex md:space-x-4 space-x-0'>
              <input type="text" onChange={(e) => setSearchUser(e.target.value)} onKeyDown={handleSearch} value={searchUser} placeholder="Search..." className="mt-1 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 mb-2 md:mb-0 w-full md:w-none p-1.5 border rounded-md" />
              </div>
            </div>
            <div className='md:flex md:space-x-4 space-x-0 space-y-3 md:space-y-0'>
              <div>
                <p className="text-sm font-medium text-gray-700">From</p>
                <input
                  type="date"
                  className="mt-1 w-full md:w-none px-2 py-1.5 md:py-1 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 border rounded-md"
                  value={fromDate}
                  onChange={(e) => { fromDateChange(e) }}
                />
              </div>
              <div>
                <p className="text-sm font-medium text-gray-700">To</p>
                <input
                  type="date"
                  className="mt-1 w-full md:w-none px-2 py-1.5 md:py-1 focus:ring-0 focus:ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 border rounded-md"
                  value={toDate}
                  onChange={(e) => toDateChange(e)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="overflow-x-auto rounded-t-xl border border-gray-200">
            <div className='flex space-x-10'>
              <p className='font-semibold m-3'>Third Party : {getNetIncome?.GetOrderWithNetAmount?.ThirdPartyNetAmount}</p>
              <p className='font-semibold m-3'>Payment Gateway : {getNetIncome?.GetOrderWithNetAmount?.ThirdCashFreeNetAmount}</p>
              <p className='font-semibold m-3'>Total Amount: {getNetIncome?.GetOrderWithNetAmount?.TotalTurnOver}</p>
              <p className='font-semibold m-3'>Net Income : {getNetIncome?.GetOrderWithNetAmount?.totalNetAmount}</p>
            </div>
            <div className='justify-end flex'>
              {/* <div className='mt-2'>
                <label>
                  Items per page:
                  <select value={limit} onChange={(e) => setLimit(Number(e.target.value))}>
                  <option value={100}>100</option>
                  <option value={50}>50</option>
                  </select>
                </label>
              </div> */}
              <div>
              <CustomPagination
        totalItems={data?.GetOrderWithNetAmount?.count  || 0}
        limit={limit}
        pageNo={page}
        handlePageClick={handlePageClick}
      />
      
              </div>
            </div>
            {getNetIncome?.GetOrderWithNetAmount?.orders.length > 0 ? (
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <td className="py-4 text-sm font-semibold bg-gray-50 px-4 border-b">No.</td>
                    <td className="py-4 text-sm font-semibold bg-gray-50 px-4 border-b">Date</td>
                    <td className="py-4 text-sm font-semibold bg-gray-50 px-4 border-b">AWB No.</td>
                    <td className="py-4 text-sm font-semibold bg-gray-50 px-4 border-b">order_percentage</td>
                    <td className="py-4 text-sm font-semibold bg-gray-50 px-4 border-b">-Third Party (Rs.)</td>
                    <td className="py-4 text-sm font-semibold bg-gray-50 px-4 border-b">-Payment Gateway (Rs.)</td>
                    <td className="py-4 text-sm font-semibold bg-gray-50 px-4 border-b">-Total Amount (Rs.)</td>
                    <td className="py-4 text-sm font-semibold bg-gray-50 px-4 border-b">Net Income (Rs.)</td>
                  </tr>
                </thead>
                <tbody>
                {getNetIncome?.GetOrderWithNetAmount?.orders.map((order, index) => (
                    <tr key={index}>
                      <td className="py-4 text-sm px-4 border-b">{index + 1}</td>
                      <td className="py-4 text-sm px-4 border-b">{new Date(order.createdAt).toLocaleDateString()}</td>
                      <td className="py-4 text-sm px-4 border-b">{order.awb_number}</td>
                      <td className="py-4 text-sm px-4 border-b">{order.order_percentage}%</td>
                      <td className="py-4 text-sm px-4 border-b">{order.thirdPartyAmount}</td>
                      <td className="py-4 text-sm px-4 border-b">{order.cashFreeAmount}</td>
                      <td className="py-4 text-sm px-4 border-b">{order.order_amount}</td>
                      <td className="py-4 text-sm px-4 border-b">{order.netAmount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminIncome;